import * as Types from '../types/types.generated';

import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useGraphqlFetcher } from '../hooks/useGraphqlFetcher';

export const CreateCardAutopayDocument = `
    mutation createCardAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $createCardAutopayInput: CreateCardAutopayInput!) {
  createCardAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    createCardAutopayInput: $createCardAutopayInput
  ) {
    code
    message
    data {
      autopayId
      autopayFirstPayDate
    }
  }
}
    `;
export const useCreateCardAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateCardAutopayMutation, TError, Types.ICreateCardAutopayMutationVariables, TContext>) =>
    useMutation<Types.ICreateCardAutopayMutation, TError, Types.ICreateCardAutopayMutationVariables, TContext>(
      ['createCardAutopay'],
      useGraphqlFetcher<Types.ICreateCardAutopayMutation, Types.ICreateCardAutopayMutationVariables>(CreateCardAutopayDocument),
      options
    );
export const CreateEftAutopayDocument = `
    mutation createEftAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $createEftAutopayInput: CreateEftAutopayInput!) {
  createEftAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    createEftAutopayInput: $createEftAutopayInput
  ) {
    code
    message
    data {
      autopayId
      autopayFirstPayDate
    }
  }
}
    `;
export const useCreateEftAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateEftAutopayMutation, TError, Types.ICreateEftAutopayMutationVariables, TContext>) =>
    useMutation<Types.ICreateEftAutopayMutation, TError, Types.ICreateEftAutopayMutationVariables, TContext>(
      ['createEftAutopay'],
      useGraphqlFetcher<Types.ICreateEftAutopayMutation, Types.ICreateEftAutopayMutationVariables>(CreateEftAutopayDocument),
      options
    );
export const SetAutopayDocument = `
    mutation setAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $statementCode: String!, $storedPaymentMethodID: String!) {
  setAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    statementCode: $statementCode
    storedPaymentMethodID: $storedPaymentMethodID
  ) {
    code
    message
    data {
      autopayId
      autopayFirstPayDate
    }
  }
}
    `;
export const useSetAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ISetAutopayMutation, TError, Types.ISetAutopayMutationVariables, TContext>) =>
    useMutation<Types.ISetAutopayMutation, TError, Types.ISetAutopayMutationVariables, TContext>(
      ['setAutopay'],
      useGraphqlFetcher<Types.ISetAutopayMutation, Types.ISetAutopayMutationVariables>(SetAutopayDocument),
      options
    );
export const PatchCardAutopayDocument = `
    mutation patchCardAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $autopayID: String!, $patchStoredPaymentCardPayloadInput: PatchStoredPaymentCardPayloadInput!) {
  patchCardAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    autopayID: $autopayID
    patchCardAutopayPayload: $patchStoredPaymentCardPayloadInput
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      autopayId
      paymentMethodName
      lastFourNumbers
      cardType
      cardholderName
      expirationDate
      billingZip
    }
  }
}
    `;
export const usePatchCardAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPatchCardAutopayMutation, TError, Types.IPatchCardAutopayMutationVariables, TContext>) =>
    useMutation<Types.IPatchCardAutopayMutation, TError, Types.IPatchCardAutopayMutationVariables, TContext>(
      ['patchCardAutopay'],
      useGraphqlFetcher<Types.IPatchCardAutopayMutation, Types.IPatchCardAutopayMutationVariables>(PatchCardAutopayDocument),
      options
    );
export const PatchEftAutopayDocument = `
    mutation patchEftAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $autopayID: String!, $patchStoredPaymentEftPayloadInput: PatchStoredPaymentEftPayloadInput!) {
  patchEftAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    autopayID: $autopayID
    patchEftAutopayPayload: $patchStoredPaymentEftPayloadInput
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      autopayId
      paymentMethodName
      accountHolderName
      accountType
      lastFourNumbers
    }
  }
}
    `;
export const usePatchEftAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPatchEftAutopayMutation, TError, Types.IPatchEftAutopayMutationVariables, TContext>) =>
    useMutation<Types.IPatchEftAutopayMutation, TError, Types.IPatchEftAutopayMutationVariables, TContext>(
      ['patchEftAutopay'],
      useGraphqlFetcher<Types.IPatchEftAutopayMutation, Types.IPatchEftAutopayMutationVariables>(PatchEftAutopayDocument),
      options
    );
export const DeleteAutopayDocument = `
    mutation deleteAutopay($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $autopayID: String!) {
  deleteAutopay(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    autopayID: $autopayID
  ) {
    code
    message
    data
  }
}
    `;
export const useDeleteAutopayMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IDeleteAutopayMutation, TError, Types.IDeleteAutopayMutationVariables, TContext>) =>
    useMutation<Types.IDeleteAutopayMutation, TError, Types.IDeleteAutopayMutationVariables, TContext>(
      ['deleteAutopay'],
      useGraphqlFetcher<Types.IDeleteAutopayMutation, Types.IDeleteAutopayMutationVariables>(DeleteAutopayDocument),
      options
    );
export const PostBillPayOneTimePaymentCreditCardDocument = `
    mutation postBillPayOneTimePaymentCreditCard($portalAccountGUID: String!, $accountNumber: String!, $cardPaymentLoad: CardPaymentInput!) {
  postOTPCard(
    billerAccountNumber: $accountNumber
    cardPaymentLoad: $cardPaymentLoad
    portalAccountGUID: $portalAccountGUID
  ) {
    code
    message
    data {
      paymentTransactionId
      spsError
      spsMessage
      spsSystemErrorCode
      spsTimestamp
    }
  }
}
    `;
export const usePostBillPayOneTimePaymentCreditCardMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPostBillPayOneTimePaymentCreditCardMutation, TError, Types.IPostBillPayOneTimePaymentCreditCardMutationVariables, TContext>) =>
    useMutation<Types.IPostBillPayOneTimePaymentCreditCardMutation, TError, Types.IPostBillPayOneTimePaymentCreditCardMutationVariables, TContext>(
      ['postBillPayOneTimePaymentCreditCard'],
      useGraphqlFetcher<Types.IPostBillPayOneTimePaymentCreditCardMutation, Types.IPostBillPayOneTimePaymentCreditCardMutationVariables>(PostBillPayOneTimePaymentCreditCardDocument),
      options
    );
export const PostBillPayOneTimePaymentEftDocument = `
    mutation postBillPayOneTimePaymentEft($portalAccountGUID: String!, $accountNumber: String!, $eftPaymentLoad: EFTPaymentInput!) {
  postOTPEft(
    billerAccountNumber: $accountNumber
    eftPaymentLoad: $eftPaymentLoad
    portalAccountGUID: $portalAccountGUID
  ) {
    code
    message
    data {
      paymentTransactionId
      spsError
      spsMessage
      spsSystemErrorCode
      spsTimestamp
    }
  }
}
    `;
export const usePostBillPayOneTimePaymentEftMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPostBillPayOneTimePaymentEftMutation, TError, Types.IPostBillPayOneTimePaymentEftMutationVariables, TContext>) =>
    useMutation<Types.IPostBillPayOneTimePaymentEftMutation, TError, Types.IPostBillPayOneTimePaymentEftMutationVariables, TContext>(
      ['postBillPayOneTimePaymentEft'],
      useGraphqlFetcher<Types.IPostBillPayOneTimePaymentEftMutation, Types.IPostBillPayOneTimePaymentEftMutationVariables>(PostBillPayOneTimePaymentEftDocument),
      options
    );
export const PostBillPayOneTimePaymentStoredPaymentDocument = `
    mutation postBillPayOneTimePaymentStoredPayment($portalAccountGUID: String!, $accountNumber: String!, $storedPaymentLoad: OneTimeStoredPaymentMethodPayloadInput!) {
  postOTPStoredPaymentMethod(
    billerAccountNumber: $accountNumber
    oneTimeStoredPaymentPayload: $storedPaymentLoad
    portalAccountGUID: $portalAccountGUID
  ) {
    code
    message
    data {
      paymentTransactionId
      spsError
      spsMessage
      spsSystemErrorCode
      spsTimestamp
    }
  }
}
    `;
export const usePostBillPayOneTimePaymentStoredPaymentMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPostBillPayOneTimePaymentStoredPaymentMutation, TError, Types.IPostBillPayOneTimePaymentStoredPaymentMutationVariables, TContext>) =>
    useMutation<Types.IPostBillPayOneTimePaymentStoredPaymentMutation, TError, Types.IPostBillPayOneTimePaymentStoredPaymentMutationVariables, TContext>(
      ['postBillPayOneTimePaymentStoredPayment'],
      useGraphqlFetcher<Types.IPostBillPayOneTimePaymentStoredPaymentMutation, Types.IPostBillPayOneTimePaymentStoredPaymentMutationVariables>(PostBillPayOneTimePaymentStoredPaymentDocument),
      options
    );
export const GetBillingAccountsDocument = `
    query getBillingAccounts($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      billing {
        accounts {
          id
          accountNumber
          divisionId
          statementCode
          amountDue
          dueByDate
          pastDueAmt
          isAutopay
          isPaperless
          accountStatus
          billingCycleFromDate
          billingCycleThruDate
          lastPayment {
            amount
            date
          }
          billingAddress {
            street
            city
            state
            zip
          }
        }
        statements {
          id
          accountNumber
          divisionId
          statementDate
          documentId
          statementCode
        }
      }
    }
  }
}
    `;
export const useGetBillingAccountsQuery = <
      TData = Types.IGetBillingAccountsQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetBillingAccountsQueryVariables,
      options?: UseQueryOptions<Types.IGetBillingAccountsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillingAccountsQuery, TError, TData>(
      variables === undefined ? ['getBillingAccounts'] : ['getBillingAccounts', variables],
      useGraphqlFetcher<Types.IGetBillingAccountsQuery, Types.IGetBillingAccountsQueryVariables>(GetBillingAccountsDocument).bind(null, variables),
      options
    );
export const GetBillingStatementPdfBytesDocument = `
    query getBillingStatementPDFBytes($documentId: String!, $portalAccountGUID: String!) {
  getPDFStatement(documentId: $documentId, portalAccountGUID: $portalAccountGUID) {
    pdfBytes
  }
}
    `;
export const useGetBillingStatementPdfBytesQuery = <
      TData = Types.IGetBillingStatementPdfBytesQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBillingStatementPdfBytesQueryVariables,
      options?: UseQueryOptions<Types.IGetBillingStatementPdfBytesQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillingStatementPdfBytesQuery, TError, TData>(
      ['getBillingStatementPDFBytes', variables],
      useGraphqlFetcher<Types.IGetBillingStatementPdfBytesQuery, Types.IGetBillingStatementPdfBytesQueryVariables>(GetBillingStatementPdfBytesDocument).bind(null, variables),
      options
    );
export const UpdatePaperlessBillingFlagDocument = `
    mutation updatePaperlessBillingFlag($portalAccountGUID: String!, $paperlessBillingFlagInput: PaperlessBillingFlagInput!) {
  updatePaperlessBillingFlag(
    portalAccountGUID: $portalAccountGUID
    paperlessBillingFlagInput: $paperlessBillingFlagInput
  ) {
    code
    message
    data {
      accountNumber
      divisionId
      statementCode
      billToAddress {
        street
        city
        state
        zip
      }
      paperlessFlag
    }
  }
}
    `;
export const useUpdatePaperlessBillingFlagMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdatePaperlessBillingFlagMutation, TError, Types.IUpdatePaperlessBillingFlagMutationVariables, TContext>) =>
    useMutation<Types.IUpdatePaperlessBillingFlagMutation, TError, Types.IUpdatePaperlessBillingFlagMutationVariables, TContext>(
      ['updatePaperlessBillingFlag'],
      useGraphqlFetcher<Types.IUpdatePaperlessBillingFlagMutation, Types.IUpdatePaperlessBillingFlagMutationVariables>(UpdatePaperlessBillingFlagDocument),
      options
    );
export const GetBillingPaymentHistoryDocument = `
    query getBillingPaymentHistory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      billing {
        paymentHistory {
          date
          amount
          accountNumber
          divisionId
          type
          isAutopay
          billingAddress {
            street
            city
            state
            zip
          }
        }
      }
    }
  }
}
    `;
export const useGetBillingPaymentHistoryQuery = <
      TData = Types.IGetBillingPaymentHistoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetBillingPaymentHistoryQueryVariables,
      options?: UseQueryOptions<Types.IGetBillingPaymentHistoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillingPaymentHistoryQuery, TError, TData>(
      variables === undefined ? ['getBillingPaymentHistory'] : ['getBillingPaymentHistory', variables],
      useGraphqlFetcher<Types.IGetBillingPaymentHistoryQuery, Types.IGetBillingPaymentHistoryQueryVariables>(GetBillingPaymentHistoryDocument).bind(null, variables),
      options
    );
export const GetBillPayPaymentRestrictionsStandaloneDocument = `
    query getBillPayPaymentRestrictionsStandalone($accountNumber: String!, $divisionID: String!, $portalAccountGUID: String!) {
  getPaymentRestrictions(
    accountNumber: $accountNumber
    divisionID: $divisionID
    portalAccountGUID: $portalAccountGUID
  ) {
    code
    message
    data {
      spsError
      spsMessage
      spsSystemErrorCode
      spsTimestamp
      paymentRestrictions {
        paymentRestrictionIndicator
        paymentMethodType
      }
    }
  }
}
    `;
export const useGetBillPayPaymentRestrictionsStandaloneQuery = <
      TData = Types.IGetBillPayPaymentRestrictionsStandaloneQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBillPayPaymentRestrictionsStandaloneQueryVariables,
      options?: UseQueryOptions<Types.IGetBillPayPaymentRestrictionsStandaloneQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillPayPaymentRestrictionsStandaloneQuery, TError, TData>(
      ['getBillPayPaymentRestrictionsStandalone', variables],
      useGraphqlFetcher<Types.IGetBillPayPaymentRestrictionsStandaloneQuery, Types.IGetBillPayPaymentRestrictionsStandaloneQueryVariables>(GetBillPayPaymentRestrictionsStandaloneDocument).bind(null, variables),
      options
    );
export const GetBillPayPaymentsSummaryDocument = `
    query getBillPayPaymentsSummary($portalAccountGUID: String!, $accountNumber: String!, $divisionId: String!) {
  getPaymentsSummary(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionId: $divisionId
  ) {
    code
    message
    data {
      accountNumber
      divisionId
      statementCode
      paymentMethodType
      totalPayments
    }
  }
}
    `;
export const useGetBillPayPaymentsSummaryQuery = <
      TData = Types.IGetBillPayPaymentsSummaryQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBillPayPaymentsSummaryQueryVariables,
      options?: UseQueryOptions<Types.IGetBillPayPaymentsSummaryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillPayPaymentsSummaryQuery, TError, TData>(
      ['getBillPayPaymentsSummary', variables],
      useGraphqlFetcher<Types.IGetBillPayPaymentsSummaryQuery, Types.IGetBillPayPaymentsSummaryQueryVariables>(GetBillPayPaymentsSummaryDocument).bind(null, variables),
      options
    );
export const GetBillPayPaymentsSummaryBeforePaymentSubmitDocument = `
    query getBillPayPaymentsSummaryBeforePaymentSubmit($portalAccountGUID: String!, $accountNumber: String!, $divisionId: String!) {
  getPaymentsSummary(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionId: $divisionId
  ) {
    code
    message
    data {
      accountNumber
      divisionId
      statementCode
      paymentMethodType
      totalPayments
    }
  }
}
    `;
export const useGetBillPayPaymentsSummaryBeforePaymentSubmitQuery = <
      TData = Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQueryVariables,
      options?: UseQueryOptions<Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQuery, TError, TData>(
      ['getBillPayPaymentsSummaryBeforePaymentSubmit', variables],
      useGraphqlFetcher<Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQuery, Types.IGetBillPayPaymentsSummaryBeforePaymentSubmitQueryVariables>(GetBillPayPaymentsSummaryBeforePaymentSubmitDocument).bind(null, variables),
      options
    );
export const GetStoredPaymentDocument = `
    query getStoredPayment($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!) {
  getStoredPayment(
    accountNumber: $accountNumber
    divisionID: $divisionID
    portalAccountGUID: $portalAccountGUID
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      statementCode
      autoPayStatus
      paymentMethodType
      storedPaymentMethodID
      paymentMethodName
      accountHolderName
      accountType
      lastFourNumbers
      cardType
      cardholderName
      expirationDate
      billingZip
      paymentChargeType
    }
  }
}
    `;
export const useGetStoredPaymentQuery = <
      TData = Types.IGetStoredPaymentQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetStoredPaymentQueryVariables,
      options?: UseQueryOptions<Types.IGetStoredPaymentQuery, TError, TData>
    ) =>
    useQuery<Types.IGetStoredPaymentQuery, TError, TData>(
      ['getStoredPayment', variables],
      useGraphqlFetcher<Types.IGetStoredPaymentQuery, Types.IGetStoredPaymentQueryVariables>(GetStoredPaymentDocument).bind(null, variables),
      options
    );
export const StoredPaymentCardDocument = `
    mutation storedPaymentCard($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $storedPaymentCardPayloadInput: StoredPaymentCardPayloadInput!) {
  storedPaymentCard(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    storedPaymentCardPayload: $storedPaymentCardPayloadInput
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      storedPaymentMethodID
      cardType
      cardholderName
      billingZip
      lastFourNumbers
      expirationDate
      paymentChargeType
      paymentMethodName
      paymentMethodType
    }
  }
}
    `;
export const useStoredPaymentCardMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IStoredPaymentCardMutation, TError, Types.IStoredPaymentCardMutationVariables, TContext>) =>
    useMutation<Types.IStoredPaymentCardMutation, TError, Types.IStoredPaymentCardMutationVariables, TContext>(
      ['storedPaymentCard'],
      useGraphqlFetcher<Types.IStoredPaymentCardMutation, Types.IStoredPaymentCardMutationVariables>(StoredPaymentCardDocument),
      options
    );
export const StoredPaymentEftDocument = `
    mutation storedPaymentEft($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $storedPaymentEftPayload: StoredPaymentEftPayload!) {
  storedPaymentEft(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    storedPaymentEftPayload: $storedPaymentEftPayload
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      storedPaymentMethodID
      accountHolderName
      accountType
      lastFourNumbers
      paymentChargeType
      paymentMethodName
      paymentMethodType
    }
  }
}
    `;
export const useStoredPaymentEftMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IStoredPaymentEftMutation, TError, Types.IStoredPaymentEftMutationVariables, TContext>) =>
    useMutation<Types.IStoredPaymentEftMutation, TError, Types.IStoredPaymentEftMutationVariables, TContext>(
      ['storedPaymentEft'],
      useGraphqlFetcher<Types.IStoredPaymentEftMutation, Types.IStoredPaymentEftMutationVariables>(StoredPaymentEftDocument),
      options
    );
export const PatchStoredPaymentCardDocument = `
    mutation patchStoredPaymentCard($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $storedPaymentMethodID: String!, $patchStoredPaymentCardPayloadInput: PatchStoredPaymentCardPayloadInput!) {
  patchStoredPaymentCard(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    storedPaymentMethodID: $storedPaymentMethodID
    patchStoredPaymentCardPayload: $patchStoredPaymentCardPayloadInput
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      storedPaymentMethodID
      paymentMethodName
      lastFourNumbers
      cardType
      cardholderName
      expirationDate
      billingZip
    }
  }
}
    `;
export const usePatchStoredPaymentCardMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPatchStoredPaymentCardMutation, TError, Types.IPatchStoredPaymentCardMutationVariables, TContext>) =>
    useMutation<Types.IPatchStoredPaymentCardMutation, TError, Types.IPatchStoredPaymentCardMutationVariables, TContext>(
      ['patchStoredPaymentCard'],
      useGraphqlFetcher<Types.IPatchStoredPaymentCardMutation, Types.IPatchStoredPaymentCardMutationVariables>(PatchStoredPaymentCardDocument),
      options
    );
export const PatchStoredPaymentEftDocument = `
    mutation patchStoredPaymentEft($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $storedPaymentMethodID: String!, $patchStoredPaymentEftPayloadInput: PatchStoredPaymentEftPayloadInput!) {
  patchStoredPaymentEft(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    storedPaymentMethodID: $storedPaymentMethodID
    patchStoredPaymentEftPayload: $patchStoredPaymentEftPayloadInput
  ) {
    code
    message
    data {
      account {
        accountNumber
        divisionID
      }
      storedPaymentMethodID
      paymentMethodName
      accountHolderName
      accountType
      lastFourNumbers
    }
  }
}
    `;
export const usePatchStoredPaymentEftMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IPatchStoredPaymentEftMutation, TError, Types.IPatchStoredPaymentEftMutationVariables, TContext>) =>
    useMutation<Types.IPatchStoredPaymentEftMutation, TError, Types.IPatchStoredPaymentEftMutationVariables, TContext>(
      ['patchStoredPaymentEft'],
      useGraphqlFetcher<Types.IPatchStoredPaymentEftMutation, Types.IPatchStoredPaymentEftMutationVariables>(PatchStoredPaymentEftDocument),
      options
    );
export const DeleteStoredPaymentDocument = `
    mutation deleteStoredPayment($portalAccountGUID: String!, $accountNumber: String!, $divisionID: String!, $storedPaymentMethodID: String!) {
  deleteStoredPayment(
    portalAccountGUID: $portalAccountGUID
    accountNumber: $accountNumber
    divisionID: $divisionID
    storedPaymentMethodID: $storedPaymentMethodID
  ) {
    code
    message
    data
  }
}
    `;
export const useDeleteStoredPaymentMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IDeleteStoredPaymentMutation, TError, Types.IDeleteStoredPaymentMutationVariables, TContext>) =>
    useMutation<Types.IDeleteStoredPaymentMutation, TError, Types.IDeleteStoredPaymentMutationVariables, TContext>(
      ['deleteStoredPayment'],
      useGraphqlFetcher<Types.IDeleteStoredPaymentMutation, Types.IDeleteStoredPaymentMutationVariables>(DeleteStoredPaymentDocument),
      options
    );
export const GetBusinessInternetEquipmentRestartStatusSubscriptionDocument = `
    subscription getBusinessInternetEquipmentRestartStatusSubscription($portalAccountGUID: String!, $portalUserGUID: String!, $accessToken: String!) {
  getBusinessInternetEquipmentRestartStatus(
    portalAccountGUID: $portalAccountGUID
    portalUserGUID: $portalUserGUID
    accessToken: $accessToken
  ) {
    restart
    equipmentIdentifier
  }
}
    `;
export const GetBusinessInternetInventoryDocument = `
    query getBusinessInternetInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        businessInternet {
          inventory {
            locations {
              BAN
              divisionId
              id
              address {
                friendlyName
                street
                city
                state
              }
              services
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetBusinessInternetInventoryQuery = <
      TData = Types.IGetBusinessInternetInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetBusinessInternetInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetBusinessInternetInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBusinessInternetInventoryQuery, TError, TData>(
      variables === undefined ? ['getBusinessInternetInventory'] : ['getBusinessInternetInventory', variables],
      useGraphqlFetcher<Types.IGetBusinessInternetInventoryQuery, Types.IGetBusinessInternetInventoryQueryVariables>(GetBusinessInternetInventoryDocument).bind(null, variables),
      options
    );
export const GetBusinessInternetDetailsDocument = `
    query getBusinessInternetDetails($portalAccountGUID: String, $businessInternetDetailsInput: BusinessInternetDetailsInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        businessInternet {
          details(businessInternetDetailsInput: $businessInternetDetailsInput) {
            BAN
            divisionId
            id
            address {
              friendlyName
              street
              city
              state
            }
            services
            equipment {
              category
              componentType
              equipmentName
              manufacturer
              model
              macAddress
              serialNumber
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetBusinessInternetDetailsQuery = <
      TData = Types.IGetBusinessInternetDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBusinessInternetDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetBusinessInternetDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBusinessInternetDetailsQuery, TError, TData>(
      ['getBusinessInternetDetails', variables],
      useGraphqlFetcher<Types.IGetBusinessInternetDetailsQuery, Types.IGetBusinessInternetDetailsQueryVariables>(GetBusinessInternetDetailsDocument).bind(null, variables),
      options
    );
export const UpdateBusinessInternetEquipmentNameDocument = `
    mutation updateBusinessInternetEquipmentName($portalAccountGUID: String!, $update: BIEquipUpdateInput!) {
  updateBusinessInternetEquipmentName(
    portalAccountGUID: $portalAccountGUID
    update: $update
  ) {
    code
    message
    data {
      portalAccountGUID
      id
      equipmentIdentifier
      equipmentName
      category
    }
  }
}
    `;
export const useUpdateBusinessInternetEquipmentNameMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateBusinessInternetEquipmentNameMutation, TError, Types.IUpdateBusinessInternetEquipmentNameMutationVariables, TContext>) =>
    useMutation<Types.IUpdateBusinessInternetEquipmentNameMutation, TError, Types.IUpdateBusinessInternetEquipmentNameMutationVariables, TContext>(
      ['updateBusinessInternetEquipmentName'],
      useGraphqlFetcher<Types.IUpdateBusinessInternetEquipmentNameMutation, Types.IUpdateBusinessInternetEquipmentNameMutationVariables>(UpdateBusinessInternetEquipmentNameDocument),
      options
    );
export const GetBusinessInternetEquipmentStatusDocument = `
    query getBusinessInternetEquipmentStatus($portalAccountGUID: String!, $businessInternetEquipmentStatusInput: BusinessInternetEquipmentStatusInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        businessInternet {
          equipment(
            businessInternetEquipmentStatusInput: $businessInternetEquipmentStatusInput
          ) {
            status
          }
        }
      }
    }
  }
}
    `;
export const useGetBusinessInternetEquipmentStatusQuery = <
      TData = Types.IGetBusinessInternetEquipmentStatusQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetBusinessInternetEquipmentStatusQueryVariables,
      options?: UseQueryOptions<Types.IGetBusinessInternetEquipmentStatusQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBusinessInternetEquipmentStatusQuery, TError, TData>(
      ['getBusinessInternetEquipmentStatus', variables],
      useGraphqlFetcher<Types.IGetBusinessInternetEquipmentStatusQuery, Types.IGetBusinessInternetEquipmentStatusQueryVariables>(GetBusinessInternetEquipmentStatusDocument).bind(null, variables),
      options
    );
export const RestartBusinessInternetEquipmentDocument = `
    mutation restartBusinessInternetEquipment($portalAccountGUID: String!, $businessInternetEquipmentRestartInput: BusinessInternetEquipmentRestartInput!) {
  restartBusinessInternetEquipment(
    portalAccountGUID: $portalAccountGUID
    businessInternetEquipmentRestartInput: $businessInternetEquipmentRestartInput
  ) {
    code
    message
    data {
      portalAccountGUID
      id
      equipmentIdentifier
      componentType
      category
    }
  }
}
    `;
export const useRestartBusinessInternetEquipmentMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IRestartBusinessInternetEquipmentMutation, TError, Types.IRestartBusinessInternetEquipmentMutationVariables, TContext>) =>
    useMutation<Types.IRestartBusinessInternetEquipmentMutation, TError, Types.IRestartBusinessInternetEquipmentMutationVariables, TContext>(
      ['restartBusinessInternetEquipment'],
      useGraphqlFetcher<Types.IRestartBusinessInternetEquipmentMutation, Types.IRestartBusinessInternetEquipmentMutationVariables>(RestartBusinessInternetEquipmentDocument),
      options
    );
export const GetBusinessVoiceInventoryDocument = `
    query getBusinessVoiceInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        businessVoice {
          inventory {
            locations {
              locationId
              accountNumber
              divisionId
              address {
                friendlyName
                street
                city
                state
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetBusinessVoiceInventoryQuery = <
      TData = Types.IGetBusinessVoiceInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetBusinessVoiceInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetBusinessVoiceInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetBusinessVoiceInventoryQuery, TError, TData>(
      variables === undefined ? ['getBusinessVoiceInventory'] : ['getBusinessVoiceInventory', variables],
      useGraphqlFetcher<Types.IGetBusinessVoiceInventoryQuery, Types.IGetBusinessVoiceInventoryQueryVariables>(GetBusinessVoiceInventoryDocument).bind(null, variables),
      options
    );
export const GetCloudSecurityInventoryDocument = `
    query getCloudSecurityInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        cloudSecurity {
          inventory {
            services {
              title
              products {
                name
                hasAdvancedSupport
                serviceId
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetCloudSecurityInventoryQuery = <
      TData = Types.IGetCloudSecurityInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetCloudSecurityInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetCloudSecurityInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetCloudSecurityInventoryQuery, TError, TData>(
      variables === undefined ? ['getCloudSecurityInventory'] : ['getCloudSecurityInventory', variables],
      useGraphqlFetcher<Types.IGetCloudSecurityInventoryQuery, Types.IGetCloudSecurityInventoryQueryVariables>(GetCloudSecurityInventoryDocument).bind(null, variables),
      options
    );
export const GetEneDetailsDocument = `
    query getEneDetails($portalAccountGUID: String, $locationId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        enterpriseNetworkEdge {
          details(locationId: $locationId) {
            id
            address {
              friendlyName
              street
              city
              state
            }
            devices {
              id
              model
              status
              ports {
                port
                circuit {
                  id
                  type
                  bandwidth
                  networkId
                  hasCircuit
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEneDetailsQuery = <
      TData = Types.IGetEneDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetEneDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetEneDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetEneDetailsQuery, TError, TData>(
      ['getEneDetails', variables],
      useGraphqlFetcher<Types.IGetEneDetailsQuery, Types.IGetEneDetailsQueryVariables>(GetEneDetailsDocument).bind(null, variables),
      options
    );
export const GetEneInventoryDocument = `
    query getEneInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        enterpriseNetworkEdge {
          inventory {
            locations {
              id
              address {
                friendlyName
                street
                city
                state
              }
              services {
                edgeDeviceLiveCount
                edgeDevicePendingCount
                hasEdge
                hasSwitch
                switchStatus
                hasVirtual
                virtualStatus
                hasVpn
                vpnStatus
                hasWifi
                wifiStatus
                hasEneManagedDevices
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEneInventoryQuery = <
      TData = Types.IGetEneInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetEneInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetEneInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetEneInventoryQuery, TError, TData>(
      variables === undefined ? ['getEneInventory'] : ['getEneInventory', variables],
      useGraphqlFetcher<Types.IGetEneInventoryQuery, Types.IGetEneInventoryQueryVariables>(GetEneInventoryDocument).bind(null, variables),
      options
    );
export const GetFiberDetailsDocument = `
    query getFiberDetails($portalAccountGUID: String, $circuitId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        fiber {
          details(circuitId: $circuitId) {
            location {
              id
              service
              hasDdosProtection
              showEquipmentTab
              address {
                friendlyName
                street
                city
                state
                zip
              }
              circuit {
                id
                bandwidth
                isType2
                isType3
              }
              wirelessBackup {
                deviceId
                deviceName
                circuitId
                servicePlan
                accountNumber
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetFiberDetailsQuery = <
      TData = Types.IGetFiberDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetFiberDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetFiberDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetFiberDetailsQuery, TError, TData>(
      ['getFiberDetails', variables],
      useGraphqlFetcher<Types.IGetFiberDetailsQuery, Types.IGetFiberDetailsQueryVariables>(GetFiberDetailsDocument).bind(null, variables),
      options
    );
export const GetFiberInventoryDocument = `
    query getFiberInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        fiber {
          inventory {
            locations {
              id
              address {
                friendlyName
                street
                city
                state
              }
              circuit {
                id
                bandwidth
                service
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetFiberInventoryQuery = <
      TData = Types.IGetFiberInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetFiberInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetFiberInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetFiberInventoryQuery, TError, TData>(
      variables === undefined ? ['getFiberInventory'] : ['getFiberInventory', variables],
      useGraphqlFetcher<Types.IGetFiberInventoryQuery, Types.IGetFiberInventoryQueryVariables>(GetFiberInventoryDocument).bind(null, variables),
      options
    );
export const GetLocationListDocument = `
    query getLocationList($portalAccountGUID: String, $orgFeatures: OrgFeaturesInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      locations {
        locationList(orgFeatures: $orgFeatures) {
          id
          address {
            friendlyName
            street
            city
            state
          }
          coordinates {
            latitude
            longitude
          }
          services {
            network {
              circuitCount
              networkId
              circuitId
            }
            fiber {
              circuitCount
              circuitId
            }
            secureInternet {
              circuitCount
              circuitId
            }
            wireless {
              accountNumber
              deviceId
            }
            managedRouterService {
              deviceCount
            }
            wavelengthCircuitCount
            activeReportCount
            tickets {
              openTicketCount
              openTicketId
            }
            hasBusinessInternet
            hasBusinessVoice
            hasManagedNetworkEdge
            hasEnterpriseNetworkEdge
            voiceTrunking {
              hasMultipleBans
              accountNumber
              isLegacyDivision
            }
            hasUnifiedComms
            hasVideo
            hasTv
            hasManagedSecurity
            hasCloudSecurity
            hasSecureAccess
          }
        }
      }
    }
  }
}
    `;
export const useGetLocationListQuery = <
      TData = Types.IGetLocationListQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetLocationListQueryVariables,
      options?: UseQueryOptions<Types.IGetLocationListQuery, TError, TData>
    ) =>
    useQuery<Types.IGetLocationListQuery, TError, TData>(
      variables === undefined ? ['getLocationList'] : ['getLocationList', variables],
      useGraphqlFetcher<Types.IGetLocationListQuery, Types.IGetLocationListQueryVariables>(GetLocationListDocument).bind(null, variables),
      options
    );
export const GetLocationSummaryDocument = `
    query getLocationSummary($portalAccountGUID: String, $locationId: String!, $orgFeatures: OrgFeaturesInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      locations {
        summary(locationId: $locationId, orgFeatures: $orgFeatures) {
          id
          address {
            friendlyName
            street
            city
            state
          }
          services {
            networkCircuits {
              circuitId
              networkId
              serviceType
              bandwidth
              isType2
              isType3
              isCircuitWithMetrics
            }
            wavelengthCircuits {
              circuitId
              bandwidth
            }
            fiberCircuits {
              circuitId
              bandwidth
              isType2
              isType3
              hasDdosProtection
              wirelessBackup {
                deviceId
                deviceName
                circuitId
                servicePlan
                accountNumber
              }
            }
            secureInternetCircuits {
              circuitId
              bandwidth
              isType2
              isType3
              hasDdosProtection
              linkOut
            }
            businessInternetAccounts {
              BAN
              divisionId
              services
            }
            businessVoiceAccounts {
              accountNumber
            }
            wirelessDevices {
              deviceId
              name
              accountNumber
              circuitId
              usageAlertPct
              backupTo
              servicePlan
              deviceUsageInGB
              planUsageInGB
              availableInGB
            }
            voiceTrunking {
              accountNumber
              isLegacyDivision
              mouSummaries {
                trunkGroupNumber
                isMain
                planMou {
                  planUsageInMins
                  planAvailInMins
                }
                sharedMou {
                  parentId
                  thisAccountInMins
                  otherAccountsInMins
                  availableInMins
                }
              }
              usageCycleEndDate
              alternateRoutingStatus {
                isActive
                phone
              }
              trunkOverflowStatus {
                isActive
                phone
              }
              didOverflowStatus {
                activeCount
                inactiveCount
              }
              didInventory {
                serviceTNs {
                  serviceType
                  servicePhone
                }
                lineTNs
              }
            }
            managedSecurityDevices {
              circuitId
            }
            unifiedCommAccounts {
              accountNumber
              productFamily
            }
            videoCircuits {
              circuitId
              serviceType
            }
            tvInventory {
              accountNumber
              services
            }
            managedNetworkEdgeDevices {
              showSiteAndConferenceManagerPortal
              edgeDevicePendingCount
              hasMneManagedDevices
              hasEdge
              hasSwitch
              hasCamera
              hasVirtual
              hasWifi
              hasTeleworker
              hasAnyConnect
              hasIoTSensor
              hasWiFiHospitality
              devices {
                id
                model
                status
                ports {
                  port
                  circuit {
                    id
                    type
                    bandwidth
                    networkId
                    hasCircuit
                  }
                }
              }
            }
            enterpriseNetworkEdgeDevices {
              edgeDevicePendingCount
              hasEneManagedDevices
              hasEdge
              hasSwitch
              hasVirtual
              hasVpn
              hasWifi
              devices {
                id
                model
                status
                ports {
                  port
                  circuit {
                    id
                    type
                    bandwidth
                    networkId
                    hasCircuit
                  }
                }
              }
            }
            managedRouterService {
              devices {
                id
                deviceModel
                isActive
                circuits {
                  id
                  type
                  networkId
                  hasCircuit
                }
              }
            }
            cloudSecurityServices {
              title
              products {
                name
                hasAdvancedSupport
                serviceId
              }
            }
            secureAccessServices {
              title
              products {
                name
                hasAdvancedSupport
                serviceId
              }
            }
            activeReports {
              reportId
              status
              type
              name
              dateRange
              createdDate
              expirationDateTime
              ... on LocSummaryNetworkReport {
                circuitId
                metrics
                timeIncrement
                __typename
              }
              ... on LocSummaryFiberReport {
                circuitId
                timeIncrement
                __typename
              }
              ... on LocSummarySecureInternetReport {
                circuitId
                timeIncrement
                __typename
              }
              ... on LocSummaryVoiceCdrReport {
                accountNumber
                phoneNumber
                serviceType
                __typename
              }
              ... on LocSummaryVoiceCallMetricsReport {
                accountNumber
                phoneNumber
                serviceType
                voiceReportType
                __typename
              }
            }
            openTickets {
              ticketId
              ticketNumber
              impactedTypes
              description
              status
              impactedCircuits
              lastUpdated
              createdDate
              companyName
              serviceTypes
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetLocationSummaryQuery = <
      TData = Types.IGetLocationSummaryQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetLocationSummaryQueryVariables,
      options?: UseQueryOptions<Types.IGetLocationSummaryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetLocationSummaryQuery, TError, TData>(
      ['getLocationSummary', variables],
      useGraphqlFetcher<Types.IGetLocationSummaryQuery, Types.IGetLocationSummaryQueryVariables>(GetLocationSummaryDocument).bind(null, variables),
      options
    );
export const ValidateRecaptchaDocument = `
    mutation validateRecaptcha($captchaResponse: String!) {
  validateRecaptcha(captchaResponse: $captchaResponse) {
    success
    challengeTs
    hostname
    errorCodes
  }
}
    `;
export const useValidateRecaptchaMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IValidateRecaptchaMutation, TError, Types.IValidateRecaptchaMutationVariables, TContext>) =>
    useMutation<Types.IValidateRecaptchaMutation, TError, Types.IValidateRecaptchaMutationVariables, TContext>(
      ['validateRecaptcha'],
      useGraphqlFetcher<Types.IValidateRecaptchaMutation, Types.IValidateRecaptchaMutationVariables>(ValidateRecaptchaDocument),
      options
    );
export const GetMneDetailsDocument = `
    query getMneDetails($portalAccountGUID: String, $locationId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedNetworkEdge {
          details(locationId: $locationId) {
            id
            showSiteAndConferenceManagerPortal
            address {
              friendlyName
              street
              city
              state
            }
            devices {
              id
              model
              status
              ports {
                port
                circuit {
                  id
                  type
                  bandwidth
                  networkId
                  hasCircuit
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMneDetailsQuery = <
      TData = Types.IGetMneDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetMneDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetMneDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMneDetailsQuery, TError, TData>(
      ['getMneDetails', variables],
      useGraphqlFetcher<Types.IGetMneDetailsQuery, Types.IGetMneDetailsQueryVariables>(GetMneDetailsDocument).bind(null, variables),
      options
    );
export const GetMneInventoryDocument = `
    query getMneInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedNetworkEdge {
          inventory {
            showSiteAndConferenceManagerPortal
            locations {
              id
              address {
                friendlyName
                street
                city
                state
              }
              services {
                edgeDeviceLiveCount
                edgeDevicePendingCount
                hasEdge
                hasSwitch
                switchStatus
                hasCamera
                cameraStatus
                hasVirtual
                virtualStatus
                hasWifi
                wifiStatus
                hasTeleworker
                teleworkerStatus
                hasAnyConnect
                anyConnectStatus
                hasIoTSensor
                ioTSensorStatus
                hasWiFiHospitality
                wiFiHospitalityStatus
                hasMneManagedDevices
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMneInventoryQuery = <
      TData = Types.IGetMneInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetMneInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetMneInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMneInventoryQuery, TError, TData>(
      variables === undefined ? ['getMneInventory'] : ['getMneInventory', variables],
      useGraphqlFetcher<Types.IGetMneInventoryQuery, Types.IGetMneInventoryQueryVariables>(GetMneInventoryDocument).bind(null, variables),
      options
    );
export const GetMrsDetailsDocument = `
    query getMrsDetails($portalAccountGUID: String, $locationId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedRouterService {
          details(locationId: $locationId) {
            address {
              friendlyName
              street
              city
              state
            }
            devices {
              id
              deviceModel
              isActive
              circuits {
                id
                type
                networkId
                hasCircuit
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMrsDetailsQuery = <
      TData = Types.IGetMrsDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetMrsDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetMrsDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMrsDetailsQuery, TError, TData>(
      ['getMrsDetails', variables],
      useGraphqlFetcher<Types.IGetMrsDetailsQuery, Types.IGetMrsDetailsQueryVariables>(GetMrsDetailsDocument).bind(null, variables),
      options
    );
export const GetMrsInventoryDocument = `
    query getMrsInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedRouterService {
          inventory {
            locations {
              id
              address {
                friendlyName
                street
                city
                state
              }
              devices {
                deviceId
                model
                isActive
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMrsInventoryQuery = <
      TData = Types.IGetMrsInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetMrsInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetMrsInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMrsInventoryQuery, TError, TData>(
      variables === undefined ? ['getMrsInventory'] : ['getMrsInventory', variables],
      useGraphqlFetcher<Types.IGetMrsInventoryQuery, Types.IGetMrsInventoryQueryVariables>(GetMrsInventoryDocument).bind(null, variables),
      options
    );
export const GetMrsTotalTrafficMetricsDocument = `
    query getMrsTotalTrafficMetrics($portalAccountGUID: String, $locationId: String!, $deviceId: String!, $dateRange: DateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedRouterService {
          metrics {
            totalTraffic(
              locationId: $locationId
              deviceId: $deviceId
              dateRange: $dateRange
            ) {
              timestamp
              received
              transmitted
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMrsTotalTrafficMetricsQuery = <
      TData = Types.IGetMrsTotalTrafficMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetMrsTotalTrafficMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetMrsTotalTrafficMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMrsTotalTrafficMetricsQuery, TError, TData>(
      ['getMrsTotalTrafficMetrics', variables],
      useGraphqlFetcher<Types.IGetMrsTotalTrafficMetricsQuery, Types.IGetMrsTotalTrafficMetricsQueryVariables>(GetMrsTotalTrafficMetricsDocument).bind(null, variables),
      options
    );
export const GetMrsTrafficByPortMetricsDocument = `
    query getMrsTrafficByPortMetrics($portalAccountGUID: String, $locationId: String!, $deviceId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedRouterService {
          metrics {
            trafficSummaryByPort(locationId: $locationId, deviceId: $deviceId) {
              portId
              portName
              interfaceName
              dataRate {
                currentRx
                currentTx
                averageRx
                averageTx
              }
              dataVolume {
                minRx
                maxRx
                totalRxBits
                totalRxPct
                minTx
                maxTx
                totalTxBits
                totalTxPct
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetMrsTrafficByPortMetricsQuery = <
      TData = Types.IGetMrsTrafficByPortMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetMrsTrafficByPortMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetMrsTrafficByPortMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetMrsTrafficByPortMetricsQuery, TError, TData>(
      ['getMrsTrafficByPortMetrics', variables],
      useGraphqlFetcher<Types.IGetMrsTrafficByPortMetricsQuery, Types.IGetMrsTrafficByPortMetricsQueryVariables>(GetMrsTrafficByPortMetricsDocument).bind(null, variables),
      options
    );
export const GetManagedSecurityInventoryDocument = `
    query getManagedSecurityInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        managedSecurity {
          inventory {
            locations {
              id
              address {
                friendlyName
                street
                city
                state
              }
              circuit {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetManagedSecurityInventoryQuery = <
      TData = Types.IGetManagedSecurityInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetManagedSecurityInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetManagedSecurityInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetManagedSecurityInventoryQuery, TError, TData>(
      variables === undefined ? ['getManagedSecurityInventory'] : ['getManagedSecurityInventory', variables],
      useGraphqlFetcher<Types.IGetManagedSecurityInventoryQuery, Types.IGetManagedSecurityInventoryQueryVariables>(GetManagedSecurityInventoryDocument).bind(null, variables),
      options
    );
export const GetNetworkCircuitDetailsDocument = `
    query getNetworkCircuitDetails($portalAccountGUID: String, $circuitId: String!, $locationId: String!, $networkId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          circuitDetails(
            circuitId: $circuitId
            locationId: $locationId
            networkId: $networkId
          ) {
            networkId
            circuitId
            bandwidth
            service
            hasDdosProtection
            isType2
            isType3
            metricServices
            address {
              friendlyName
              street
              city
              state
              zip
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkCircuitDetailsQuery = <
      TData = Types.IGetNetworkCircuitDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNetworkCircuitDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkCircuitDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkCircuitDetailsQuery, TError, TData>(
      ['getNetworkCircuitDetails', variables],
      useGraphqlFetcher<Types.IGetNetworkCircuitDetailsQuery, Types.IGetNetworkCircuitDetailsQueryVariables>(GetNetworkCircuitDetailsDocument).bind(null, variables),
      options
    );
export const GetNetworkCircuitsDocument = `
    query getNetworkCircuits($portalAccountGUID: String, $networkId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          circuits(networkId: $networkId) {
            networkId
            name
            locations {
              locationId
              circuitId
              isHub
              coordinates {
                latitude
                longitude
              }
              address {
                friendlyName
                street
                city
                state
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkCircuitsQuery = <
      TData = Types.IGetNetworkCircuitsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNetworkCircuitsQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkCircuitsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkCircuitsQuery, TError, TData>(
      ['getNetworkCircuits', variables],
      useGraphqlFetcher<Types.IGetNetworkCircuitsQuery, Types.IGetNetworkCircuitsQueryVariables>(GetNetworkCircuitsDocument).bind(null, variables),
      options
    );
export const GetNetworkCloudConnectDocument = `
    query getNetworkCloudConnect($portalAccountGUID: String, $gatewayId: String!, $networkId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          cloudConnect(gatewayId: $gatewayId, networkId: $networkId) {
            location {
              address {
                friendlyName
                street
                city
                state
                zip
              }
              circuits {
                id
                service
                bandwidth
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkCloudConnectQuery = <
      TData = Types.IGetNetworkCloudConnectQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNetworkCloudConnectQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkCloudConnectQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkCloudConnectQuery, TError, TData>(
      ['getNetworkCloudConnect', variables],
      useGraphqlFetcher<Types.IGetNetworkCloudConnectQuery, Types.IGetNetworkCloudConnectQueryVariables>(GetNetworkCloudConnectDocument).bind(null, variables),
      options
    );
export const GetNetworkDetailsDocument = `
    query getNetworkDetails($portalAccountGUID: String, $networkId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          details(networkId: $networkId) {
            id
            type
            name
            locations {
              id
              isHub
              service
              hasDdosProtection
              isCircuitWithMetrics
              showEquipmentTab
              coordinates {
                latitude
                longitude
              }
              address {
                friendlyName
                street
                city
                state
                zip
              }
              circuit {
                id
                bandwidth
                isType2
                isType3
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkDetailsQuery = <
      TData = Types.IGetNetworkDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNetworkDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkDetailsQuery, TError, TData>(
      ['getNetworkDetails', variables],
      useGraphqlFetcher<Types.IGetNetworkDetailsQuery, Types.IGetNetworkDetailsQueryVariables>(GetNetworkDetailsDocument).bind(null, variables),
      options
    );
export const GetNetworkInventoryDocument = `
    query getNetworkInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          inventory {
            networks {
              id
              type
              name
              locations {
                id
                isHub
                service
                address {
                  friendlyName
                  street
                  city
                  state
                }
                circuit {
                  id
                  bandwidth
                }
              }
              gateway {
                id
                circuits {
                  id
                  location
                  service
                  bandwidth
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkInventoryQuery = <
      TData = Types.IGetNetworkInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetNetworkInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkInventoryQuery, TError, TData>(
      variables === undefined ? ['getNetworkInventory'] : ['getNetworkInventory', variables],
      useGraphqlFetcher<Types.IGetNetworkInventoryQuery, Types.IGetNetworkInventoryQueryVariables>(GetNetworkInventoryDocument).bind(null, variables),
      options
    );
export const GetNetworkCircuitReportDetailsDocument = `
    query getNetworkCircuitReportDetails($portalAccountGUID: String, $circuitId: String!, $locationId: String!, $networkId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        network {
          circuitDetails(
            circuitId: $circuitId
            locationId: $locationId
            networkId: $networkId
          ) {
            metricServices
            address {
              friendlyName
              street
              city
              state
              zip
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetNetworkCircuitReportDetailsQuery = <
      TData = Types.IGetNetworkCircuitReportDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNetworkCircuitReportDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetNetworkCircuitReportDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNetworkCircuitReportDetailsQuery, TError, TData>(
      ['getNetworkCircuitReportDetails', variables],
      useGraphqlFetcher<Types.IGetNetworkCircuitReportDetailsQuery, Types.IGetNetworkCircuitReportDetailsQueryVariables>(GetNetworkCircuitReportDetailsDocument).bind(null, variables),
      options
    );
export const AimForgotPasswordVerifyUsernameDocument = `
    mutation aimForgotPasswordVerifyUsername($input: VerifyUsernameInput!) {
  forgotPasswordVerifyUsername(input: $input) {
    token
  }
}
    `;
export const useAimForgotPasswordVerifyUsernameMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimForgotPasswordVerifyUsernameMutation, TError, Types.IAimForgotPasswordVerifyUsernameMutationVariables, TContext>) =>
    useMutation<Types.IAimForgotPasswordVerifyUsernameMutation, TError, Types.IAimForgotPasswordVerifyUsernameMutationVariables, TContext>(
      ['aimForgotPasswordVerifyUsername'],
      useGraphqlFetcher<Types.IAimForgotPasswordVerifyUsernameMutation, Types.IAimForgotPasswordVerifyUsernameMutationVariables>(AimForgotPasswordVerifyUsernameDocument),
      options
    );
export const AimForgotPasswordPasswordResetDocument = `
    mutation aimForgotPasswordPasswordReset($input: ForgotPasswordPasswordResetInput!) {
  forgotPasswordPasswordReset(input: $input) {
    message
  }
}
    `;
export const useAimForgotPasswordPasswordResetMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimForgotPasswordPasswordResetMutation, TError, Types.IAimForgotPasswordPasswordResetMutationVariables, TContext>) =>
    useMutation<Types.IAimForgotPasswordPasswordResetMutation, TError, Types.IAimForgotPasswordPasswordResetMutationVariables, TContext>(
      ['aimForgotPasswordPasswordReset'],
      useGraphqlFetcher<Types.IAimForgotPasswordPasswordResetMutation, Types.IAimForgotPasswordPasswordResetMutationVariables>(AimForgotPasswordPasswordResetDocument),
      options
    );
export const AimForgotPasswordValidatePasswordResetTokenDocument = `
    mutation aimForgotPasswordValidatePasswordResetToken($token: String!) {
  forgotPasswordValidatePasswordResetToken(token: $token) {
    token
  }
}
    `;
export const useAimForgotPasswordValidatePasswordResetTokenMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimForgotPasswordValidatePasswordResetTokenMutation, TError, Types.IAimForgotPasswordValidatePasswordResetTokenMutationVariables, TContext>) =>
    useMutation<Types.IAimForgotPasswordValidatePasswordResetTokenMutation, TError, Types.IAimForgotPasswordValidatePasswordResetTokenMutationVariables, TContext>(
      ['aimForgotPasswordValidatePasswordResetToken'],
      useGraphqlFetcher<Types.IAimForgotPasswordValidatePasswordResetTokenMutation, Types.IAimForgotPasswordValidatePasswordResetTokenMutationVariables>(AimForgotPasswordValidatePasswordResetTokenDocument),
      options
    );
export const AimForgotPasswordUpdatePasswordDocument = `
    mutation aimForgotPasswordUpdatePassword($input: ForgotPasswordUpdatePasswordInput!) {
  forgotPasswordUpdatePassword(input: $input) {
    message
    username
  }
}
    `;
export const useAimForgotPasswordUpdatePasswordMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimForgotPasswordUpdatePasswordMutation, TError, Types.IAimForgotPasswordUpdatePasswordMutationVariables, TContext>) =>
    useMutation<Types.IAimForgotPasswordUpdatePasswordMutation, TError, Types.IAimForgotPasswordUpdatePasswordMutationVariables, TContext>(
      ['aimForgotPasswordUpdatePassword'],
      useGraphqlFetcher<Types.IAimForgotPasswordUpdatePasswordMutation, Types.IAimForgotPasswordUpdatePasswordMutationVariables>(AimForgotPasswordUpdatePasswordDocument),
      options
    );
export const AimChangePasswordUpdateUserPasswordDocument = `
    mutation aimChangePasswordUpdateUserPassword($input: ChangePasswordUpdateUserPasswordInput!) {
  changePasswordUpdateUserPassword(input: $input) {
    message
  }
}
    `;
export const useAimChangePasswordUpdateUserPasswordMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimChangePasswordUpdateUserPasswordMutation, TError, Types.IAimChangePasswordUpdateUserPasswordMutationVariables, TContext>) =>
    useMutation<Types.IAimChangePasswordUpdateUserPasswordMutation, TError, Types.IAimChangePasswordUpdateUserPasswordMutationVariables, TContext>(
      ['aimChangePasswordUpdateUserPassword'],
      useGraphqlFetcher<Types.IAimChangePasswordUpdateUserPasswordMutation, Types.IAimChangePasswordUpdateUserPasswordMutationVariables>(AimChangePasswordUpdateUserPasswordDocument),
      options
    );
export const AimCreatePasswordAndActivateUserDocument = `
    mutation aimCreatePasswordAndActivateUser($token: String!, $input: CreatePasswordAndActivateUserInput!) {
  createPasswordAndActivateUser(token: $token, input: $input) {
    username
  }
}
    `;
export const useAimCreatePasswordAndActivateUserMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimCreatePasswordAndActivateUserMutation, TError, Types.IAimCreatePasswordAndActivateUserMutationVariables, TContext>) =>
    useMutation<Types.IAimCreatePasswordAndActivateUserMutation, TError, Types.IAimCreatePasswordAndActivateUserMutationVariables, TContext>(
      ['aimCreatePasswordAndActivateUser'],
      useGraphqlFetcher<Types.IAimCreatePasswordAndActivateUserMutation, Types.IAimCreatePasswordAndActivateUserMutationVariables>(AimCreatePasswordAndActivateUserDocument),
      options
    );
export const AimValidateActivationTokenDocument = `
    query aimValidateActivationToken($token: String!) {
  validateActivationToken(token: $token) {
    token
  }
}
    `;
export const useAimValidateActivationTokenQuery = <
      TData = Types.IAimValidateActivationTokenQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IAimValidateActivationTokenQueryVariables,
      options?: UseQueryOptions<Types.IAimValidateActivationTokenQuery, TError, TData>
    ) =>
    useQuery<Types.IAimValidateActivationTokenQuery, TError, TData>(
      ['aimValidateActivationToken', variables],
      useGraphqlFetcher<Types.IAimValidateActivationTokenQuery, Types.IAimValidateActivationTokenQueryVariables>(AimValidateActivationTokenDocument).bind(null, variables),
      options
    );
export const AimVerifyUserActivationInfoDocument = `
    mutation aimVerifyUserActivationInfo($input: ValidateUserActivationInput!, $token: String) {
  validateUserActivationInformation(input: $input, token: $token) {
    token
  }
}
    `;
export const useAimVerifyUserActivationInfoMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimVerifyUserActivationInfoMutation, TError, Types.IAimVerifyUserActivationInfoMutationVariables, TContext>) =>
    useMutation<Types.IAimVerifyUserActivationInfoMutation, TError, Types.IAimVerifyUserActivationInfoMutationVariables, TContext>(
      ['aimVerifyUserActivationInfo'],
      useGraphqlFetcher<Types.IAimVerifyUserActivationInfoMutation, Types.IAimVerifyUserActivationInfoMutationVariables>(AimVerifyUserActivationInfoDocument),
      options
    );
export const AimSelfRegistrationCreateUserDocument = `
    mutation aimSelfRegistrationCreateUser($input: SelfRegistrationCreateUserInput!) {
  selfRegistrationCreateUser(input: $input) {
    token
    message
    userId
  }
}
    `;
export const useAimSelfRegistrationCreateUserMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimSelfRegistrationCreateUserMutation, TError, Types.IAimSelfRegistrationCreateUserMutationVariables, TContext>) =>
    useMutation<Types.IAimSelfRegistrationCreateUserMutation, TError, Types.IAimSelfRegistrationCreateUserMutationVariables, TContext>(
      ['aimSelfRegistrationCreateUser'],
      useGraphqlFetcher<Types.IAimSelfRegistrationCreateUserMutation, Types.IAimSelfRegistrationCreateUserMutationVariables>(AimSelfRegistrationCreateUserDocument),
      options
    );
export const AimSelfRegistrationUserProfileInformationDocument = `
    mutation aimSelfRegistrationUserProfileInformation($input: SelfRegistrationUserProfileInformationInput!) {
  selfRegistrationUserProfileInformation(input: $input) {
    email
    token
    userId
    status
  }
}
    `;
export const useAimSelfRegistrationUserProfileInformationMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimSelfRegistrationUserProfileInformationMutation, TError, Types.IAimSelfRegistrationUserProfileInformationMutationVariables, TContext>) =>
    useMutation<Types.IAimSelfRegistrationUserProfileInformationMutation, TError, Types.IAimSelfRegistrationUserProfileInformationMutationVariables, TContext>(
      ['aimSelfRegistrationUserProfileInformation'],
      useGraphqlFetcher<Types.IAimSelfRegistrationUserProfileInformationMutation, Types.IAimSelfRegistrationUserProfileInformationMutationVariables>(AimSelfRegistrationUserProfileInformationDocument),
      options
    );
export const AimSelfRegistrationValidateUserProfileByBanDocument = `
    mutation aimSelfRegistrationValidateUserProfileByBan($input: SelfRegistrationValidateUserProfileByBanInput!) {
  selfRegistrationValidateUserProfileByBan(input: $input) {
    token
  }
}
    `;
export const useAimSelfRegistrationValidateUserProfileByBanMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimSelfRegistrationValidateUserProfileByBanMutation, TError, Types.IAimSelfRegistrationValidateUserProfileByBanMutationVariables, TContext>) =>
    useMutation<Types.IAimSelfRegistrationValidateUserProfileByBanMutation, TError, Types.IAimSelfRegistrationValidateUserProfileByBanMutationVariables, TContext>(
      ['aimSelfRegistrationValidateUserProfileByBan'],
      useGraphqlFetcher<Types.IAimSelfRegistrationValidateUserProfileByBanMutation, Types.IAimSelfRegistrationValidateUserProfileByBanMutationVariables>(AimSelfRegistrationValidateUserProfileByBanDocument),
      options
    );
export const AimSelfRegistrationValidateUserProfileByCircuitIdDocument = `
    mutation aimSelfRegistrationValidateUserProfileByCircuitId($input: SelfRegistrationValidateUserProfileByCircuitInput!) {
  selfRegistrationValidateUserProfileByCircuitId(input: $input) {
    token
  }
}
    `;
export const useAimSelfRegistrationValidateUserProfileByCircuitIdMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutation, TError, Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutationVariables, TContext>) =>
    useMutation<Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutation, TError, Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutationVariables, TContext>(
      ['aimSelfRegistrationValidateUserProfileByCircuitId'],
      useGraphqlFetcher<Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutation, Types.IAimSelfRegistrationValidateUserProfileByCircuitIdMutationVariables>(AimSelfRegistrationValidateUserProfileByCircuitIdDocument),
      options
    );
export const AimSendRegistrationEmailDocument = `
    query aimSendRegistrationEmail($userId: String!) {
  sendRegistrationEmail(userId: $userId) {
    content
    smtpResponse
    token
  }
}
    `;
export const useAimSendRegistrationEmailQuery = <
      TData = Types.IAimSendRegistrationEmailQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IAimSendRegistrationEmailQueryVariables,
      options?: UseQueryOptions<Types.IAimSendRegistrationEmailQuery, TError, TData>
    ) =>
    useQuery<Types.IAimSendRegistrationEmailQuery, TError, TData>(
      ['aimSendRegistrationEmail', variables],
      useGraphqlFetcher<Types.IAimSendRegistrationEmailQuery, Types.IAimSendRegistrationEmailQueryVariables>(AimSendRegistrationEmailDocument).bind(null, variables),
      options
    );
export const CreateEquipmentReportDocument = `
    mutation createEquipmentReport($portalAccountGUID: String!, $circuitId: String!, $requestBody: CreateEquipmentReportRequest!) {
  putEquipmentAvailabilityReport(
    portalAccountGUID: $portalAccountGUID
    circuitId: $circuitId
    requestBody: $requestBody
  ) {
    code
    message
    data {
      createdDate
      expirationDateTime
      parameters {
        aggregateType
        endDateTime
        entityType
        filename
        gatewayName
        metricTypes
        startDateTime
        timezoneOffset
      }
      portalUserGUID
      reportId
      reportStatus
    }
  }
}
    `;
export const useCreateEquipmentReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateEquipmentReportMutation, TError, Types.ICreateEquipmentReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateEquipmentReportMutation, TError, Types.ICreateEquipmentReportMutationVariables, TContext>(
      ['createEquipmentReport'],
      useGraphqlFetcher<Types.ICreateEquipmentReportMutation, Types.ICreateEquipmentReportMutationVariables>(CreateEquipmentReportDocument),
      options
    );
export const GetReportFileDocument = `
    query getReportFile($portalAccountGUID: String, $reportId: String!, $reportType: CGRReportTypeEnum!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        reports {
          reportFile(reportId: $reportId, reportType: $reportType) {
            file
            mimeType
            fileName
          }
        }
      }
    }
  }
}
    `;
export const useGetReportFileQuery = <
      TData = Types.IGetReportFileQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetReportFileQueryVariables,
      options?: UseQueryOptions<Types.IGetReportFileQuery, TError, TData>
    ) =>
    useQuery<Types.IGetReportFileQuery, TError, TData>(
      ['getReportFile', variables],
      useGraphqlFetcher<Types.IGetReportFileQuery, Types.IGetReportFileQueryVariables>(GetReportFileDocument).bind(null, variables),
      options
    );
export const GetReportListDocument = `
    query getReportList($portalAccountGUID: String, $orgFeatures: OrgReportFeaturesInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        reports {
          list(orgFeatures: $orgFeatures) {
            reports {
              id
              status
              type
              name
              dateRange
              address {
                friendlyName
                street
                city
                state
              }
              createdBy
              createdDate
              expirationDateTime
              ... on NetworkReport {
                circuitId
                metrics
                timeIncrement
                __typename
              }
              ... on FiberReport {
                circuitId
                timeIncrement
                __typename
              }
              ... on SecureInternetReport {
                circuitId
                timeIncrement
                __typename
              }
              ... on VoiceCdrReport {
                accountNumber
                phoneNumber
                serviceType
                __typename
              }
              ... on VoiceCallMetricsReport {
                accountNumber
                phoneNumber
                serviceType
                voiceReportType
                __typename
              }
              ... on VoiceBlockedCallReport {
                reportSelection
                selectionDetails
                __typename
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetReportListQuery = <
      TData = Types.IGetReportListQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetReportListQueryVariables,
      options?: UseQueryOptions<Types.IGetReportListQuery, TError, TData>
    ) =>
    useQuery<Types.IGetReportListQuery, TError, TData>(
      variables === undefined ? ['getReportList'] : ['getReportList', variables],
      useGraphqlFetcher<Types.IGetReportListQuery, Types.IGetReportListQueryVariables>(GetReportListDocument).bind(null, variables),
      options
    );
export const CreateNetworkReportDocument = `
    mutation createNetworkReport($portalAccountGUID: String!, $circuitId: String!, $requestBody: CreateNetworkReportRequest!) {
  putNetworkServicesReport(
    portalAccountGUID: $portalAccountGUID
    circuitId: $circuitId
    requestBody: $requestBody
  ) {
    code
    message
    data {
      createdDate
      expirationDateTime
      parameters {
        aggregateType
        endDateTime
        entityType
        filename
        gatewayName
        metricTypes
        startDateTime
        timezoneOffset
      }
      portalUserGUID
      reportId
      reportStatus
    }
  }
}
    `;
export const useCreateNetworkReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateNetworkReportMutation, TError, Types.ICreateNetworkReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateNetworkReportMutation, TError, Types.ICreateNetworkReportMutationVariables, TContext>(
      ['createNetworkReport'],
      useGraphqlFetcher<Types.ICreateNetworkReportMutation, Types.ICreateNetworkReportMutationVariables>(CreateNetworkReportDocument),
      options
    );
export const GetSecureAccessInventoryDocument = `
    query getSecureAccessInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        secureAccess {
          inventory {
            services {
              title
              products {
                name
                hasAdvancedSupport
                serviceId
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetSecureAccessInventoryQuery = <
      TData = Types.IGetSecureAccessInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetSecureAccessInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetSecureAccessInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetSecureAccessInventoryQuery, TError, TData>(
      variables === undefined ? ['getSecureAccessInventory'] : ['getSecureAccessInventory', variables],
      useGraphqlFetcher<Types.IGetSecureAccessInventoryQuery, Types.IGetSecureAccessInventoryQueryVariables>(GetSecureAccessInventoryDocument).bind(null, variables),
      options
    );
export const GetSecureInternetInventoryDocument = `
    query getSecureInternetInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        secureInternet {
          inventory {
            locations {
              locationId
              address {
                friendlyName
                street
                city
                state
              }
              circuit {
                circuitId
                bandwidth
                service
              }
              linkOut
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetSecureInternetInventoryQuery = <
      TData = Types.IGetSecureInternetInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetSecureInternetInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetSecureInternetInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetSecureInternetInventoryQuery, TError, TData>(
      variables === undefined ? ['getSecureInternetInventory'] : ['getSecureInternetInventory', variables],
      useGraphqlFetcher<Types.IGetSecureInternetInventoryQuery, Types.IGetSecureInternetInventoryQueryVariables>(GetSecureInternetInventoryDocument).bind(null, variables),
      options
    );
export const GetSecureInternetDetailsDocument = `
    query getSecureInternetDetails($portalAccountGUID: String, $circuitId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        secureInternet {
          details(circuitId: $circuitId) {
            location {
              locationId
              service
              hasDdosProtection
              metricsServices
              address {
                friendlyName
                street
                city
                state
                zip
              }
              circuit {
                circuitId
                bandwidth
                isType2
                isType3
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetSecureInternetDetailsQuery = <
      TData = Types.IGetSecureInternetDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetSecureInternetDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetSecureInternetDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetSecureInternetDetailsQuery, TError, TData>(
      ['getSecureInternetDetails', variables],
      useGraphqlFetcher<Types.IGetSecureInternetDetailsQuery, Types.IGetSecureInternetDetailsQueryVariables>(GetSecureInternetDetailsDocument).bind(null, variables),
      options
    );
export const AllBillingPreferencesForAimUserDocument = `
    query AllBillingPreferencesForAIMUser($input: AllBillingPreferencesForAIMUserRequest!) {
  allBillingPreferencesForAIMUser(input: $input) {
    aimUserId
    aimOrganizationId
    preference
  }
}
    `;
export const useAllBillingPreferencesForAimUserQuery = <
      TData = Types.IAllBillingPreferencesForAimUserQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IAllBillingPreferencesForAimUserQueryVariables,
      options?: UseQueryOptions<Types.IAllBillingPreferencesForAimUserQuery, TError, TData>
    ) =>
    useQuery<Types.IAllBillingPreferencesForAimUserQuery, TError, TData>(
      ['AllBillingPreferencesForAIMUser', variables],
      useGraphqlFetcher<Types.IAllBillingPreferencesForAimUserQuery, Types.IAllBillingPreferencesForAimUserQueryVariables>(AllBillingPreferencesForAimUserDocument).bind(null, variables),
      options
    );
export const UpdateBillingPreferencesForAimUserDocument = `
    mutation UpdateBillingPreferencesForAIMUser($input: UpdateBillingPreferencesForAIMUserRequest!) {
  updateBillingPreferencesForAIMUser(input: $input) {
    aimUserId
    message
  }
}
    `;
export const useUpdateBillingPreferencesForAimUserMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateBillingPreferencesForAimUserMutation, TError, Types.IUpdateBillingPreferencesForAimUserMutationVariables, TContext>) =>
    useMutation<Types.IUpdateBillingPreferencesForAimUserMutation, TError, Types.IUpdateBillingPreferencesForAimUserMutationVariables, TContext>(
      ['UpdateBillingPreferencesForAIMUser'],
      useGraphqlFetcher<Types.IUpdateBillingPreferencesForAimUserMutation, Types.IUpdateBillingPreferencesForAimUserMutationVariables>(UpdateBillingPreferencesForAimUserDocument),
      options
    );
export const AddEventLogDocument = `
    mutation addEventLog($portalAccountGUID: String!, $eventInput: EventLogInput!) {
  addEventLog(portalAccountGUID: $portalAccountGUID, eventInput: $eventInput) {
    code
  }
}
    `;
export const useAddEventLogMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAddEventLogMutation, TError, Types.IAddEventLogMutationVariables, TContext>) =>
    useMutation<Types.IAddEventLogMutation, TError, Types.IAddEventLogMutationVariables, TContext>(
      ['addEventLog'],
      useGraphqlFetcher<Types.IAddEventLogMutation, Types.IAddEventLogMutationVariables>(AddEventLogDocument),
      options
    );
export const GetEventLogsDocument = `
    query getEventLogs($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      settings {
        eventLogs {
          id
          status
          type
          createdBy
          timestamp
          changeDetails {
            ... on EventLogStatusChange {
              isActive
              phoneNumber
              __typename
            }
            ... on EventLogUserProfileChange {
              phoneNumber
              fullName
              isPasswordChanged
              capabilities
              __typename
            }
            ... on EventLogLocationChange {
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogBillPayChange {
              accountNumber
              divisionId
              statementCode
              paymentAmount
              paymentDate
              paymentType
              cardNumberLastFour
              expirationDate
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              referenceNumber
              paymentMethodName
              spmId
              __typename
            }
            ... on EventLogSavedPaymentsChange {
              accountNumber
              divisionId
              paymentType
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              cardNumberLastFour
              expirationDate
              paymentMethodName
              spmId
              __typename
            }
            ... on EventLogAutoPayChange {
              accountNumber
              divisionId
              statementCode
              paymentType
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              cardNumberLastFour
              expirationDate
              spmId
              autopayId
              autopayEmailSentTo
              autopayFirstPayDate
              __typename
            }
            ... on EventLogCallGuardChange {
              requestLevel
              transactionId
              changeRequestFeature {
                featureId
                featureSetting
              }
              __typename
            }
            ... on EventLogStatementPDFDownloadChange {
              accountNumber
              documentId
              statementDate
              statementCode
              __typename
            }
            ... on EventLogBusinessInternetEquipmentNameChange {
              category
              macAddress
              deviceName
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogBusinessInternetEquipmentRestartChange {
              category
              macAddress
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogUpdatePaperlessBillingFlag {
              paperlessFlag
              accountNumber
              divisionId
              statementCode
              street
              city
              state
              zip
              __typename
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEventLogsQuery = <
      TData = Types.IGetEventLogsQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetEventLogsQueryVariables,
      options?: UseQueryOptions<Types.IGetEventLogsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetEventLogsQuery, TError, TData>(
      variables === undefined ? ['getEventLogs'] : ['getEventLogs', variables],
      useGraphqlFetcher<Types.IGetEventLogsQuery, Types.IGetEventLogsQueryVariables>(GetEventLogsDocument).bind(null, variables),
      options
    );
export const GetEventLogsNextDocument = `
    query getEventLogsNext($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      settings {
        eventLogs {
          id
          status
          type
          createdBy
          timestamp
          changeDetails {
            ... on EventLogStatusChange {
              isActive
              phoneNumber
              __typename
            }
            ... on EventLogUserProfileChange {
              phoneNumber
              fullName
              isPasswordChanged
              capabilities
              __typename
            }
            ... on EventLogLocationChange {
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogBillPayChange {
              accountNumber
              divisionId
              statementCode
              paymentAmount
              paymentDate
              paymentType
              cardNumberLastFour
              expirationDate
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              referenceNumber
              paymentMethodName
              spmId
              __typename
            }
            ... on EventLogSavedPaymentsChange {
              accountNumber
              divisionId
              paymentType
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              cardNumberLastFour
              expirationDate
              paymentMethodName
              spmId
              __typename
            }
            ... on EventLogAutoPayChange {
              accountNumber
              divisionId
              statementCode
              paymentType
              checkingAccLastFour
              savingsAccLastFour
              routingNumberLastFour
              cardNumberLastFour
              expirationDate
              spmId
              autopayId
              autopayEmailSentTo
              autopayFirstPayDate
              __typename
            }
            ... on EventLogCallGuardChange {
              requestLevel
              transactionId
              changeRequestFeature {
                featureId
                featureSetting
              }
              __typename
            }
            ... on EventLogStatementPDFDownloadChange {
              accountNumber
              documentId
              statementDate
              statementCode
              __typename
            }
            ... on EventLogBusinessInternetEquipmentNameChange {
              category
              macAddress
              deviceName
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogBusinessInternetEquipmentRestartChange {
              category
              macAddress
              friendlyName
              street
              city
              state
              __typename
            }
            ... on EventLogUpdatePaperlessBillingFlag {
              paperlessFlag
              accountNumber
              divisionId
              statementCode
              street
              city
              state
              zip
              __typename
            }
            ... on EventLogPaymentHistoryReceiptDownloadChange {
              accountNumber
              divisionId
              paymentAmount
              paymentDate
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEventLogsNextQuery = <
      TData = Types.IGetEventLogsNextQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetEventLogsNextQueryVariables,
      options?: UseQueryOptions<Types.IGetEventLogsNextQuery, TError, TData>
    ) =>
    useQuery<Types.IGetEventLogsNextQuery, TError, TData>(
      variables === undefined ? ['getEventLogsNext'] : ['getEventLogsNext', variables],
      useGraphqlFetcher<Types.IGetEventLogsNextQuery, Types.IGetEventLogsNextQueryVariables>(GetEventLogsNextDocument).bind(null, variables),
      options
    );
export const GetLocationNamesDocument = `
    query getLocationNames($portalAccountGUID: String, $orgFeatures: OrgFeaturesInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      settings {
        locationNames(orgFeatures: $orgFeatures) {
          id
          address {
            friendlyName
            street
            city
            state
          }
        }
      }
    }
  }
}
    `;
export const useGetLocationNamesQuery = <
      TData = Types.IGetLocationNamesQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetLocationNamesQueryVariables,
      options?: UseQueryOptions<Types.IGetLocationNamesQuery, TError, TData>
    ) =>
    useQuery<Types.IGetLocationNamesQuery, TError, TData>(
      variables === undefined ? ['getLocationNames'] : ['getLocationNames', variables],
      useGraphqlFetcher<Types.IGetLocationNamesQuery, Types.IGetLocationNamesQueryVariables>(GetLocationNamesDocument).bind(null, variables),
      options
    );
export const UpdateUserPasswordDocument = `
    mutation updateUserPassword($requestId: String!, $targetPortalUserGUID: String!, $userUpdatePasswordRequest: UserUpdatePasswordRequest!) {
  updateUserPassword(
    requestId: $requestId
    targetPortalUserGUID: $targetPortalUserGUID
    userUpdatePasswordRequest: $userUpdatePasswordRequest
  ) {
    code
    data {
      content
      emailSubject
      smtpResponse
      token
    }
    message
    token
  }
}
    `;
export const useUpdateUserPasswordMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateUserPasswordMutation, TError, Types.IUpdateUserPasswordMutationVariables, TContext>) =>
    useMutation<Types.IUpdateUserPasswordMutation, TError, Types.IUpdateUserPasswordMutationVariables, TContext>(
      ['updateUserPassword'],
      useGraphqlFetcher<Types.IUpdateUserPasswordMutation, Types.IUpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument),
      options
    );
export const UpdateAliasDocument = `
    mutation updateAlias($portalAccountGUID: String!, $updateAliasRequest: UpdateAliasTypeRequest) {
  updateAlias(
    portalAccountGUID: $portalAccountGUID
    updateAliasRequest: $updateAliasRequest
  ) {
    message
    code
    data {
      portalAccountGUID
      typeAliasHierarchies {
        alias {
          id
          friendlyName
          address {
            city
            state
            street
            street2
            zip
          }
        }
      }
    }
  }
}
    `;
export const useUpdateAliasMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateAliasMutation, TError, Types.IUpdateAliasMutationVariables, TContext>) =>
    useMutation<Types.IUpdateAliasMutation, TError, Types.IUpdateAliasMutationVariables, TContext>(
      ['updateAlias'],
      useGraphqlFetcher<Types.IUpdateAliasMutation, Types.IUpdateAliasMutationVariables>(UpdateAliasDocument),
      options
    );
export const AimAllAccessPoliciesForUserDocument = `
    query aimAllAccessPoliciesForUser($showRelatedAccessPolicies: Boolean) {
  allAccessPoliciesForUser(showRelatedAccessPolicies: $showRelatedAccessPolicies) {
    id
    name
    description
    isInternalAccessPolicy
    services {
      id
      name
      availableCapabilities {
        id
        name
      }
    }
    capabilities {
      id
      name
    }
  }
}
    `;
export const useAimAllAccessPoliciesForUserQuery = <
      TData = Types.IAimAllAccessPoliciesForUserQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimAllAccessPoliciesForUserQueryVariables,
      options?: UseQueryOptions<Types.IAimAllAccessPoliciesForUserQuery, TError, TData>
    ) =>
    useQuery<Types.IAimAllAccessPoliciesForUserQuery, TError, TData>(
      variables === undefined ? ['aimAllAccessPoliciesForUser'] : ['aimAllAccessPoliciesForUser', variables],
      useGraphqlFetcher<Types.IAimAllAccessPoliciesForUserQuery, Types.IAimAllAccessPoliciesForUserQueryVariables>(AimAllAccessPoliciesForUserDocument).bind(null, variables),
      options
    );
export const AimAllUsersDocument = `
    query aimAllUsers($all: String, $firstName: String, $lastName: String, $email: String, $phoneNumber: String, $isInternalUser: Boolean, $organizationIds: [String!], $accessPolicyIds: [String!], $offset: Int, $limit: Int, $sortBy: String) {
  allUsers(
    all: $all
    firstName: $firstName
    lastName: $lastName
    email: $email
    phoneNumber: $phoneNumber
    isInternalUser: $isInternalUser
    organizationIds: $organizationIds
    accessPolicyIds: $accessPolicyIds
    offset: $offset
    limit: $limit
    sortBy: $sortBy
  ) {
    users {
      id
      firstName
      lastName
      email
      phone
      accessPolicyIds
      organizationIds
      status
    }
    pagination {
      offset
      limit
      total
    }
  }
}
    `;
export const useAimAllUsersQuery = <
      TData = Types.IAimAllUsersQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimAllUsersQueryVariables,
      options?: UseQueryOptions<Types.IAimAllUsersQuery, TError, TData>
    ) =>
    useQuery<Types.IAimAllUsersQuery, TError, TData>(
      variables === undefined ? ['aimAllUsers'] : ['aimAllUsers', variables],
      useGraphqlFetcher<Types.IAimAllUsersQuery, Types.IAimAllUsersQueryVariables>(AimAllUsersDocument).bind(null, variables),
      options
    );
export const AimDeleteUserDataDocument = `
    mutation aimDeleteUserData($input: DeleteUsersInput!) {
  deleteUsersData(input: $input) {
    id
    firstName
    lastName
  }
}
    `;
export const useAimDeleteUserDataMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimDeleteUserDataMutation, TError, Types.IAimDeleteUserDataMutationVariables, TContext>) =>
    useMutation<Types.IAimDeleteUserDataMutation, TError, Types.IAimDeleteUserDataMutationVariables, TContext>(
      ['aimDeleteUserData'],
      useGraphqlFetcher<Types.IAimDeleteUserDataMutation, Types.IAimDeleteUserDataMutationVariables>(AimDeleteUserDataDocument),
      options
    );
export const GetExternalUsersDocument = `
    query getExternalUsers($portalAccountGUID: String, $showDeleted: Boolean!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      users {
        external(showDeleted: $showDeleted) {
          id
          status
        }
      }
    }
  }
}
    `;
export const useGetExternalUsersQuery = <
      TData = Types.IGetExternalUsersQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetExternalUsersQueryVariables,
      options?: UseQueryOptions<Types.IGetExternalUsersQuery, TError, TData>
    ) =>
    useQuery<Types.IGetExternalUsersQuery, TError, TData>(
      ['getExternalUsers', variables],
      useGraphqlFetcher<Types.IGetExternalUsersQuery, Types.IGetExternalUsersQueryVariables>(GetExternalUsersDocument).bind(null, variables),
      options
    );
export const AimSaveUserDataDocument = `
    mutation aimSaveUserData($input: CreateUserInput!) {
  saveUserData(input: $input) {
    id
    firstName
    lastName
    email
    phone
    pid
    isInternalUser
    organizationIds
    accessPolicyIds
  }
}
    `;
export const useAimSaveUserDataMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimSaveUserDataMutation, TError, Types.IAimSaveUserDataMutationVariables, TContext>) =>
    useMutation<Types.IAimSaveUserDataMutation, TError, Types.IAimSaveUserDataMutationVariables, TContext>(
      ['aimSaveUserData'],
      useGraphqlFetcher<Types.IAimSaveUserDataMutation, Types.IAimSaveUserDataMutationVariables>(AimSaveUserDataDocument),
      options
    );
export const SendRegistrationEmailDocument = `
    query sendRegistrationEmail($portalAccountGUID: String, $portalUserGUID: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      users {
        sendRegistrationEmail(portalUserGUID: $portalUserGUID) {
          content
          smtpResponse
          token
          emailSubject
        }
      }
    }
  }
}
    `;
export const useSendRegistrationEmailQuery = <
      TData = Types.ISendRegistrationEmailQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.ISendRegistrationEmailQueryVariables,
      options?: UseQueryOptions<Types.ISendRegistrationEmailQuery, TError, TData>
    ) =>
    useQuery<Types.ISendRegistrationEmailQuery, TError, TData>(
      ['sendRegistrationEmail', variables],
      useGraphqlFetcher<Types.ISendRegistrationEmailQuery, Types.ISendRegistrationEmailQueryVariables>(SendRegistrationEmailDocument).bind(null, variables),
      options
    );
export const AimUpdateUserDataDocument = `
    mutation aimUpdateUserData($input: UpdateUserInput!) {
  updateUserData(input: $input) {
    id
    firstName
    lastName
    email
    phone
    pid
    isInternalUser
    organizationIds
    accessPolicyIds
  }
}
    `;
export const useAimUpdateUserDataMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAimUpdateUserDataMutation, TError, Types.IAimUpdateUserDataMutationVariables, TContext>) =>
    useMutation<Types.IAimUpdateUserDataMutation, TError, Types.IAimUpdateUserDataMutationVariables, TContext>(
      ['aimUpdateUserData'],
      useGraphqlFetcher<Types.IAimUpdateUserDataMutation, Types.IAimUpdateUserDataMutationVariables>(AimUpdateUserDataDocument),
      options
    );
export const CreateDirectoryDocument = `
    mutation createDirectory($portalAccountGUID: String!, $updateFileOrDirectoryRequest: CreateDirectoryRequest!) {
  postDirectory(
    portalAccountGUID: $portalAccountGUID
    updateFileOrDirectoryRequest: $updateFileOrDirectoryRequest
  ) {
    data {
      id
      parent
      name
      archived
      author {
        id
        firstName
        lastName
      }
      lastModifiedBy {
        id
        firstName
        lastName
      }
      createdDate
      updatedDate
    }
  }
}
    `;
export const useCreateDirectoryMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateDirectoryMutation, TError, Types.ICreateDirectoryMutationVariables, TContext>) =>
    useMutation<Types.ICreateDirectoryMutation, TError, Types.ICreateDirectoryMutationVariables, TContext>(
      ['createDirectory'],
      useGraphqlFetcher<Types.ICreateDirectoryMutation, Types.ICreateDirectoryMutationVariables>(CreateDirectoryDocument),
      options
    );
export const GetPastFileVersionsDocument = `
    query getPastFileVersions($portalAccountGUID: String, $versionData: [VersionData!]!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        storage {
          pastFileVersions(versionData: $versionData) {
            createdBy
            createdOn
            fileName
            id
            mimetype
            size
            lastModifiedBy
            updatedOn
          }
        }
      }
    }
  }
}
    `;
export const useGetPastFileVersionsQuery = <
      TData = Types.IGetPastFileVersionsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetPastFileVersionsQueryVariables,
      options?: UseQueryOptions<Types.IGetPastFileVersionsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetPastFileVersionsQuery, TError, TData>(
      ['getPastFileVersions', variables],
      useGraphqlFetcher<Types.IGetPastFileVersionsQuery, Types.IGetPastFileVersionsQueryVariables>(GetPastFileVersionsDocument).bind(null, variables),
      options
    );
export const GetStorageDetailsDocument = `
    query getStorageDetails($portalAccountGUID: String, $archived: Boolean!, $accountId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        storage {
          details(archived: $archived, id: $accountId) {
            id
            name
            folders {
              id
              parent
              name
              size
              createdOn
              createdBy
              archived
            }
            files {
              id
              parent
              size
              title
              fileName
              mimetype
              createdOn
              createdBy
              description
              archived
              versionId
              versionNumber
              versionsData {
                objectId
                versionNumber
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetStorageDetailsQuery = <
      TData = Types.IGetStorageDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetStorageDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetStorageDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetStorageDetailsQuery, TError, TData>(
      ['getStorageDetails', variables],
      useGraphqlFetcher<Types.IGetStorageDetailsQuery, Types.IGetStorageDetailsQueryVariables>(GetStorageDetailsDocument).bind(null, variables),
      options
    );
export const GetDownloadFileByIdDocument = `
    query getDownloadFileById($id: String!, $portalAccountGUID: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        storage {
          download(id: $id)
        }
      }
    }
  }
}
    `;
export const useGetDownloadFileByIdQuery = <
      TData = Types.IGetDownloadFileByIdQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetDownloadFileByIdQueryVariables,
      options?: UseQueryOptions<Types.IGetDownloadFileByIdQuery, TError, TData>
    ) =>
    useQuery<Types.IGetDownloadFileByIdQuery, TError, TData>(
      ['getDownloadFileById', variables],
      useGraphqlFetcher<Types.IGetDownloadFileByIdQuery, Types.IGetDownloadFileByIdQueryVariables>(GetDownloadFileByIdDocument).bind(null, variables),
      options
    );
export const GetStorageInventoryDocument = `
    query getStorageInventory($portalAccountGUID: String, $archived: Boolean!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        storage {
          inventory(archived: $archived) {
            accounts {
              id
              name
              folders {
                id
                parent
                name
                size
                createdOn
                createdBy
                archived
              }
              files {
                id
                parent
                size
                title
                fileName
                mimetype
                createdOn
                createdBy
                description
                archived
                versionId
                versionNumber
                versionsData {
                  objectId
                  versionNumber
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetStorageInventoryQuery = <
      TData = Types.IGetStorageInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetStorageInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetStorageInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetStorageInventoryQuery, TError, TData>(
      ['getStorageInventory', variables],
      useGraphqlFetcher<Types.IGetStorageInventoryQuery, Types.IGetStorageInventoryQueryVariables>(GetStorageInventoryDocument).bind(null, variables),
      options
    );
export const UpdateFileDocument = `
    mutation updateFile($portalAccountGUID: String!, $fileId: String!, $updateFileRequest: StorageUpdateFileRequest!) {
  putFile(
    portalAccountGUID: $portalAccountGUID
    id: $fileId
    updateFileOrDirectoryRequest: $updateFileRequest
  ) {
    code
    message
    data {
      fileSize
      id
      name
      title
      description
      archived
      lastModifiedBy {
        id
        firstName
        lastName
      }
      createdDate
      updatedDate
    }
  }
}
    `;
export const useUpdateFileMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateFileMutation, TError, Types.IUpdateFileMutationVariables, TContext>) =>
    useMutation<Types.IUpdateFileMutation, TError, Types.IUpdateFileMutationVariables, TContext>(
      ['updateFile'],
      useGraphqlFetcher<Types.IUpdateFileMutation, Types.IUpdateFileMutationVariables>(UpdateFileDocument),
      options
    );
export const UpdateDirectoryDocument = `
    mutation updateDirectory($portalAccountGUID: String!, $folderId: String!, $updateFileOrDirectoryRequest: UpdateFileOrDirectoryRequest!) {
  putDirectory(
    portalAccountGUID: $portalAccountGUID
    id: $folderId
    updateFileOrDirectoryRequest: $updateFileOrDirectoryRequest
  ) {
    data {
      id
      parent
      name
      archived
      author {
        id
        firstName
        lastName
      }
      lastModifiedBy {
        id
        firstName
        lastName
      }
      createdDate
      updatedDate
      description
    }
  }
}
    `;
export const useUpdateDirectoryMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateDirectoryMutation, TError, Types.IUpdateDirectoryMutationVariables, TContext>) =>
    useMutation<Types.IUpdateDirectoryMutation, TError, Types.IUpdateDirectoryMutationVariables, TContext>(
      ['updateDirectory'],
      useGraphqlFetcher<Types.IUpdateDirectoryMutation, Types.IUpdateDirectoryMutationVariables>(UpdateDirectoryDocument),
      options
    );
export const UploadStorageFileDocument = `
    mutation uploadStorageFile($description: String, $file: Upload!, $name: String, $parent: String, $portalAccountGUID: String!, $title: String, $versionId: String) {
  postFile(
    description: $description
    file: $file
    name: $name
    parent: $parent
    portalAccountGUID: $portalAccountGUID
    title: $title
    versionId: $versionId
  ) {
    code
    message
    data {
      archived
      author {
        firstName
        lastName
        id
      }
      createdDate
      contents {
        archived
        author {
          firstName
          lastName
          id
        }
        createdDate
        description
        fileSize
        fileType
        id
        lastModifiedBy {
          firstName
          lastName
          id
        }
        name
        objectType
        title
        updatedDate
        versionId
        versionNumber
        versions {
          objectId
          versionNumber
        }
      }
      description
      fileSize
      fileType
      id
      lastModifiedBy {
        firstName
        lastName
        id
      }
      name
      objectType
      title
      updatedDate
      versionId
      versionNumber
      versions {
        objectId
        versionNumber
      }
    }
  }
}
    `;
export const useUploadStorageFileMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUploadStorageFileMutation, TError, Types.IUploadStorageFileMutationVariables, TContext>) =>
    useMutation<Types.IUploadStorageFileMutation, TError, Types.IUploadStorageFileMutationVariables, TContext>(
      ['uploadStorageFile'],
      useGraphqlFetcher<Types.IUploadStorageFileMutation, Types.IUploadStorageFileMutationVariables>(UploadStorageFileDocument),
      options
    );
export const CreateTicketDocument = `
    mutation createTicket($portalAccountGUID: String!, $createTicketRequest: CreateTicketRequestModel!) {
  createTicket(
    portalAccountGUID: $portalAccountGUID
    createTicketRequest: $createTicketRequest
  ) {
    id
    status
    ticketNumber
    transactionId
  }
}
    `;
export const useCreateTicketMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateTicketMutation, TError, Types.ICreateTicketMutationVariables, TContext>) =>
    useMutation<Types.ICreateTicketMutation, TError, Types.ICreateTicketMutationVariables, TContext>(
      ['createTicket'],
      useGraphqlFetcher<Types.ICreateTicketMutation, Types.ICreateTicketMutationVariables>(CreateTicketDocument),
      options
    );
export const GetCreateTicketInfoDocument = `
    query getCreateTicketInfo($portalAccountGUID: String, $orgFeatures: OrgTicketFeaturesInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tickets {
          createInfo(orgFeatures: $orgFeatures) {
            locations {
              id
              address {
                friendlyName
                street
                city
                state
                zip
              }
            }
            circuits {
              name
              locationId
              circuitId
              impactedType
              impactedService
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetCreateTicketInfoQuery = <
      TData = Types.IGetCreateTicketInfoQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetCreateTicketInfoQueryVariables,
      options?: UseQueryOptions<Types.IGetCreateTicketInfoQuery, TError, TData>
    ) =>
    useQuery<Types.IGetCreateTicketInfoQuery, TError, TData>(
      variables === undefined ? ['getCreateTicketInfo'] : ['getCreateTicketInfo', variables],
      useGraphqlFetcher<Types.IGetCreateTicketInfoQuery, Types.IGetCreateTicketInfoQueryVariables>(GetCreateTicketInfoDocument).bind(null, variables),
      options
    );
export const GetTicketDetailsV2Document = `
    query getTicketDetailsV2($portalAccountGUID: String, $ticketId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tickets {
          details(ticketId: $ticketId) {
            ticketId
            ticketNumber
            internalId
            companyName
            impactedTypes
            description
            condition
            status
            impactedCircuits {
              circuitId
              name
              type
              networkId
            }
            serviceTypes
            resolution
            outageCause
            stopClockTimes {
              totalStopClockDuration
              outageDuration
            }
            billingNumber
            source
            assignedAction
            allowUpdates
            address {
              friendlyName
              street
              city
              state
              zip
            }
            technicalContacts {
              firstName
              lastName
              email
              phone
            }
            siteContacts {
              firstName
              lastName
              email
              phone
            }
            reportedByContact {
              firstName
              lastName
              email
              phone
            }
            createdDate
            resolvedDate
            lastUpdated
            closedDate
            businessImpact
          }
        }
      }
    }
  }
}
    `;
export const useGetTicketDetailsV2Query = <
      TData = Types.IGetTicketDetailsV2Query,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetTicketDetailsV2QueryVariables,
      options?: UseQueryOptions<Types.IGetTicketDetailsV2Query, TError, TData>
    ) =>
    useQuery<Types.IGetTicketDetailsV2Query, TError, TData>(
      ['getTicketDetailsV2', variables],
      useGraphqlFetcher<Types.IGetTicketDetailsV2Query, Types.IGetTicketDetailsV2QueryVariables>(GetTicketDetailsV2Document).bind(null, variables),
      options
    );
export const CreateTicketNoteDocument = `
    mutation createTicketNote($portalAccountGUID: String!, $id: String!, $ticket: CreateNoteRequest!) {
  updateTicketNotes(
    portalAccountGUID: $portalAccountGUID
    id: $id
    ticket: $ticket
  ) {
    id
    status
    ticketNumber
    transactionId
  }
}
    `;
export const useCreateTicketNoteMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateTicketNoteMutation, TError, Types.ICreateTicketNoteMutationVariables, TContext>) =>
    useMutation<Types.ICreateTicketNoteMutation, TError, Types.ICreateTicketNoteMutationVariables, TContext>(
      ['createTicketNote'],
      useGraphqlFetcher<Types.ICreateTicketNoteMutation, Types.ICreateTicketNoteMutationVariables>(CreateTicketNoteDocument),
      options
    );
export const CreateTicketNoteAttachmentDocument = `
    mutation createTicketNoteAttachment($portalAccountGUID: String!, $ticketId: String!, $attachment: Upload!, $external_submitter_id: String!) {
  uploadAttachment(
    portalAccountGUID: $portalAccountGUID
    id: $ticketId
    external_submitter_id: $external_submitter_id
    files: $attachment
  ) {
    id
    status
    ticketNumber
    transactionId
  }
}
    `;
export const useCreateTicketNoteAttachmentMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateTicketNoteAttachmentMutation, TError, Types.ICreateTicketNoteAttachmentMutationVariables, TContext>) =>
    useMutation<Types.ICreateTicketNoteAttachmentMutation, TError, Types.ICreateTicketNoteAttachmentMutationVariables, TContext>(
      ['createTicketNoteAttachment'],
      useGraphqlFetcher<Types.ICreateTicketNoteAttachmentMutation, Types.ICreateTicketNoteAttachmentMutationVariables>(CreateTicketNoteAttachmentDocument),
      options
    );
export const GetTicketHistoryDocument = `
    query getTicketHistory($portalAccountGUID: String, $ticketId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tickets {
          history(ticketId: $ticketId) {
            ... on TicketHistoryNote {
              __typename
              type
              message
              attachedFile {
                icon
                name
                size
              }
              createdBy
              dateCreated
              isSupport
            }
            ... on TicketHistoryAction {
              __typename
              action
              dateCreated
              isClockOn
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetTicketHistoryQuery = <
      TData = Types.IGetTicketHistoryQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetTicketHistoryQueryVariables,
      options?: UseQueryOptions<Types.IGetTicketHistoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetTicketHistoryQuery, TError, TData>(
      ['getTicketHistory', variables],
      useGraphqlFetcher<Types.IGetTicketHistoryQuery, Types.IGetTicketHistoryQueryVariables>(GetTicketHistoryDocument).bind(null, variables),
      options
    );
export const GetTicketInventoryDocument = `
    query getTicketInventory($portalAccountGUID: String, $limit: Int, $offset: Int, $sort: SortingInput, $filters: TicketInvFilteringInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tickets {
          inventory(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
            tickets {
              ticketId
              items {
                ticketNumber
                internalId
                companyName
                impactedTypes
                description
                condition
                status
                impactedCircuits
                serviceTypes
                resolution
                outageCause
                stopClockTimes {
                  totalStopClockDuration
                  outageDuration
                }
                billingNumber
                source
                address {
                  friendlyName
                  street
                  city
                  state
                  zip
                }
                technicalContact {
                  firstName
                  lastName
                  email
                  phone
                }
                siteContact {
                  firstName
                  lastName
                  email
                  phone
                }
                reportedByContact {
                  firstName
                  lastName
                  email
                  phone
                }
                createdDate
                resolvedDate
                lastUpdated
                closedDate
              }
            }
            pageInfo {
              totalItems
            }
            filterOptions {
              statusOptions
              typeOptions
              companyNameOptions
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetTicketInventoryQuery = <
      TData = Types.IGetTicketInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetTicketInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetTicketInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetTicketInventoryQuery, TError, TData>(
      variables === undefined ? ['getTicketInventory'] : ['getTicketInventory', variables],
      useGraphqlFetcher<Types.IGetTicketInventoryQuery, Types.IGetTicketInventoryQueryVariables>(GetTicketInventoryDocument).bind(null, variables),
      options
    );
export const GetTicketSummaryDocument = `
    query getTicketSummary($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tickets {
          summary {
            status {
              inProgressCount
              needAttentionCount
              alarmCount
            }
            resolution {
              resolution
              count
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetTicketSummaryQuery = <
      TData = Types.IGetTicketSummaryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetTicketSummaryQueryVariables,
      options?: UseQueryOptions<Types.IGetTicketSummaryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetTicketSummaryQuery, TError, TData>(
      variables === undefined ? ['getTicketSummary'] : ['getTicketSummary', variables],
      useGraphqlFetcher<Types.IGetTicketSummaryQuery, Types.IGetTicketSummaryQueryVariables>(GetTicketSummaryDocument).bind(null, variables),
      options
    );
export const GetUnifiedCommsCdrsDocument = `
    query getUnifiedCommsCdrs($portalAccountGUID: String, $accountNumber: String!, $filters: UcCdrsFilteringInput!, $limit: Int, $offset: Int, $sort: UcCdrSortingInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        unifiedComms {
          callDetailRecords(
            accountNumber: $accountNumber
            limit: $limit
            offset: $offset
            sort: $sort
            filters: $filters
          ) {
            accountNumber
            accountAddress {
              friendlyName
              street
              city
              state
            }
            callRecords {
              id
              numberServiceId
              callStartTime
              fromPhoneNumber
              toPhoneNumber
              location
              durationInSecs
              callCharges
              callType
              callPath
            }
            pageInfo {
              totalItems
            }
            filterOptions {
              services
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
              callTypes
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetUnifiedCommsCdrsQuery = <
      TData = Types.IGetUnifiedCommsCdrsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetUnifiedCommsCdrsQueryVariables,
      options?: UseQueryOptions<Types.IGetUnifiedCommsCdrsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetUnifiedCommsCdrsQuery, TError, TData>(
      ['getUnifiedCommsCdrs', variables],
      useGraphqlFetcher<Types.IGetUnifiedCommsCdrsQuery, Types.IGetUnifiedCommsCdrsQueryVariables>(GetUnifiedCommsCdrsDocument).bind(null, variables),
      options
    );
export const GetUnifiedCommsInventoryDocument = `
    query getUnifiedCommsInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        unifiedComms {
          inventory {
            locations {
              id
              accountNumber
              productFamily
              type
              address {
                friendlyName
                street
                city
                state
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetUnifiedCommsInventoryQuery = <
      TData = Types.IGetUnifiedCommsInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetUnifiedCommsInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetUnifiedCommsInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetUnifiedCommsInventoryQuery, TError, TData>(
      variables === undefined ? ['getUnifiedCommsInventory'] : ['getUnifiedCommsInventory', variables],
      useGraphqlFetcher<Types.IGetUnifiedCommsInventoryQuery, Types.IGetUnifiedCommsInventoryQueryVariables>(GetUnifiedCommsInventoryDocument).bind(null, variables),
      options
    );
export const GetUnifiedCommsReportInfoDocument = `
    query getUnifiedCommsReportInfo($portalAccountGUID: String, $accountNumber: String!, $filters: UcInfoFilteringInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        unifiedComms {
          reportInfo(accountNumber: $accountNumber, filters: $filters) {
            accountNumber
            accountAddress {
              friendlyName
              street
              city
              state
              zip
            }
            trunk {
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
            }
            tollFree {
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetUnifiedCommsReportInfoQuery = <
      TData = Types.IGetUnifiedCommsReportInfoQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetUnifiedCommsReportInfoQueryVariables,
      options?: UseQueryOptions<Types.IGetUnifiedCommsReportInfoQuery, TError, TData>
    ) =>
    useQuery<Types.IGetUnifiedCommsReportInfoQuery, TError, TData>(
      ['getUnifiedCommsReportInfo', variables],
      useGraphqlFetcher<Types.IGetUnifiedCommsReportInfoQuery, Types.IGetUnifiedCommsReportInfoQueryVariables>(GetUnifiedCommsReportInfoDocument).bind(null, variables),
      options
    );
export const VideoManagementGetAllChannelsDocument = `
    query videoManagementGetAllChannels($orgId: String!) {
  getChannels(orgId: $orgId) {
    data {
      frequency
      id
      maxUploads
      maxVideoBytes
      name
      s3Bucket
      s3BucketPath
      uploadCount
    }
  }
}
    `;
export const useVideoManagementGetAllChannelsQuery = <
      TData = Types.IVideoManagementGetAllChannelsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IVideoManagementGetAllChannelsQueryVariables,
      options?: UseQueryOptions<Types.IVideoManagementGetAllChannelsQuery, TError, TData>
    ) =>
    useQuery<Types.IVideoManagementGetAllChannelsQuery, TError, TData>(
      ['videoManagementGetAllChannels', variables],
      useGraphqlFetcher<Types.IVideoManagementGetAllChannelsQuery, Types.IVideoManagementGetAllChannelsQueryVariables>(VideoManagementGetAllChannelsDocument).bind(null, variables),
      options
    );
export const GetTvInventoryDocument = `
    query getTvInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        tv {
          inventory {
            locations {
              locationId
              accountNumber
              divisionId
              address {
                friendlyName
                street
                city
                state
                zip
              }
              services
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetTvInventoryQuery = <
      TData = Types.IGetTvInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetTvInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetTvInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetTvInventoryQuery, TError, TData>(
      variables === undefined ? ['getTvInventory'] : ['getTvInventory', variables],
      useGraphqlFetcher<Types.IGetTvInventoryQuery, Types.IGetTvInventoryQueryVariables>(GetTvInventoryDocument).bind(null, variables),
      options
    );
export const VideoManagementGetVideoInfoDocument = `
    query videoManagementGetVideoInfo($orgId: String!, $channelId: String!) {
  getVideoInfo(orgId: $orgId, channelId: $channelId) {
    data {
      channelName
      fileName
      id
      path
      s3Bucket
      s3BucketPath
      status
      uploadTimeStamp
      mdsChannelId
      mdsCustomerId
    }
  }
}
    `;
export const useVideoManagementGetVideoInfoQuery = <
      TData = Types.IVideoManagementGetVideoInfoQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IVideoManagementGetVideoInfoQueryVariables,
      options?: UseQueryOptions<Types.IVideoManagementGetVideoInfoQuery, TError, TData>
    ) =>
    useQuery<Types.IVideoManagementGetVideoInfoQuery, TError, TData>(
      ['videoManagementGetVideoInfo', variables],
      useGraphqlFetcher<Types.IVideoManagementGetVideoInfoQuery, Types.IVideoManagementGetVideoInfoQueryVariables>(VideoManagementGetVideoInfoDocument).bind(null, variables),
      options
    );
export const GetVideoInventoryDocument = `
    query getVideoInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        video {
          inventory {
            locations {
              id
              service
              address {
                friendlyName
                street
                city
                state
              }
              circuit {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVideoInventoryQuery = <
      TData = Types.IGetVideoInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVideoInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetVideoInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVideoInventoryQuery, TError, TData>(
      variables === undefined ? ['getVideoInventory'] : ['getVideoInventory', variables],
      useGraphqlFetcher<Types.IGetVideoInventoryQuery, Types.IGetVideoInventoryQueryVariables>(GetVideoInventoryDocument).bind(null, variables),
      options
    );
export const GetVirtualSecurityFiltersDocument = `
    query getVirtualSecurityFilters($portalAccountGUID: String, $siteName: String!, $policyId: String!, $filterType: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        virtualSecurity {
          filters(siteName: $siteName, policyId: $policyId, filterType: $filterType) {
            category
            status
          }
        }
      }
    }
  }
}
    `;
export const useGetVirtualSecurityFiltersQuery = <
      TData = Types.IGetVirtualSecurityFiltersQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVirtualSecurityFiltersQueryVariables,
      options?: UseQueryOptions<Types.IGetVirtualSecurityFiltersQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVirtualSecurityFiltersQuery, TError, TData>(
      ['getVirtualSecurityFilters', variables],
      useGraphqlFetcher<Types.IGetVirtualSecurityFiltersQuery, Types.IGetVirtualSecurityFiltersQueryVariables>(GetVirtualSecurityFiltersDocument).bind(null, variables),
      options
    );
export const GetVirtualSecurityInventoryDocument = `
    query getVirtualSecurityInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        virtualSecurity {
          inventory {
            sites {
              id
              name
              serviceTier
              policies {
                id
                siteName
                services
                name
                action
                sequence
                profiles {
                  webFilter
                  dnsFilter
                }
                toPort {
                  name
                  details {
                    alias
                    role
                    ip
                  }
                }
                fromPort {
                  name
                  details {
                    alias
                    description
                    ip
                    access
                    icmpRedirect
                  }
                }
                source
                destination
                nat
                logging
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVirtualSecurityInventoryQuery = <
      TData = Types.IGetVirtualSecurityInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVirtualSecurityInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetVirtualSecurityInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVirtualSecurityInventoryQuery, TError, TData>(
      variables === undefined ? ['getVirtualSecurityInventory'] : ['getVirtualSecurityInventory', variables],
      useGraphqlFetcher<Types.IGetVirtualSecurityInventoryQuery, Types.IGetVirtualSecurityInventoryQueryVariables>(GetVirtualSecurityInventoryDocument).bind(null, variables),
      options
    );
export const GetVirtualSecurityServiceDetailsDocument = `
    query getVirtualSecurityServiceDetails($portalAccountGUID: String, $siteName: String!, $policyId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        virtualSecurity {
          serviceDetails(siteName: $siteName, policyId: $policyId) {
            id
            siteName
            name
            services {
              name
              protocol
              settings {
                portSource
                portTarget
                ip
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVirtualSecurityServiceDetailsQuery = <
      TData = Types.IGetVirtualSecurityServiceDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVirtualSecurityServiceDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetVirtualSecurityServiceDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVirtualSecurityServiceDetailsQuery, TError, TData>(
      ['getVirtualSecurityServiceDetails', variables],
      useGraphqlFetcher<Types.IGetVirtualSecurityServiceDetailsQuery, Types.IGetVirtualSecurityServiceDetailsQueryVariables>(GetVirtualSecurityServiceDetailsDocument).bind(null, variables),
      options
    );
export const AddVoiceCgChangeRequestDocument = `
    mutation addVoiceCGChangeRequest($callGuardChangeRequest: ChangeRequestBody!) {
  addCGChangeRequest(callGuardChangeRequest: $callGuardChangeRequest) {
    code
    message
  }
}
    `;
export const useAddVoiceCgChangeRequestMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IAddVoiceCgChangeRequestMutation, TError, Types.IAddVoiceCgChangeRequestMutationVariables, TContext>) =>
    useMutation<Types.IAddVoiceCgChangeRequestMutation, TError, Types.IAddVoiceCgChangeRequestMutationVariables, TContext>(
      ['addVoiceCGChangeRequest'],
      useGraphqlFetcher<Types.IAddVoiceCgChangeRequestMutation, Types.IAddVoiceCgChangeRequestMutationVariables>(AddVoiceCgChangeRequestDocument),
      options
    );
export const GetVoiceCallGuardEnterpriseCsvDataDocument = `
    query getVoiceCallGuardEnterpriseCSVData($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          callGuardEnterpriseCSVData {
            locations {
              accountNumber
              id
              address {
                friendlyName
                street
                city
                state
                zip
              }
              didNumbers {
                trunkGroupNumber
                phoneNumber
                callBlocking
                callerIdAlerts
                serviceType
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceCallGuardEnterpriseCsvDataQuery = <
      TData = Types.IGetVoiceCallGuardEnterpriseCsvDataQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVoiceCallGuardEnterpriseCsvDataQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceCallGuardEnterpriseCsvDataQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceCallGuardEnterpriseCsvDataQuery, TError, TData>(
      variables === undefined ? ['getVoiceCallGuardEnterpriseCSVData'] : ['getVoiceCallGuardEnterpriseCSVData', variables],
      useGraphqlFetcher<Types.IGetVoiceCallGuardEnterpriseCsvDataQuery, Types.IGetVoiceCallGuardEnterpriseCsvDataQueryVariables>(GetVoiceCallGuardEnterpriseCsvDataDocument).bind(null, variables),
      options
    );
export const GetVoiceCallGuardSingleLocationDocument = `
    query getVoiceCallGuardSingleLocation($portalAccountGUID: String, $locationId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          callGuardSingleLocation(locationId: $locationId) {
            locationId
            accountNumber
            address {
              friendlyName
              street
              city
              state
              zip
            }
            trunkServices {
              trunkGroupNumber
              callBlockingDonutGraph {
                onCount
                offCount
              }
              callerIdAlertsDonutGraph {
                onCount
                offCount
              }
            }
            didNumbers {
              trunkGroupNumber
              phoneNumber
              callBlocking
              callerIdAlerts
              serviceType
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceCallGuardSingleLocationQuery = <
      TData = Types.IGetVoiceCallGuardSingleLocationQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceCallGuardSingleLocationQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceCallGuardSingleLocationQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceCallGuardSingleLocationQuery, TError, TData>(
      ['getVoiceCallGuardSingleLocation', variables],
      useGraphqlFetcher<Types.IGetVoiceCallGuardSingleLocationQuery, Types.IGetVoiceCallGuardSingleLocationQueryVariables>(GetVoiceCallGuardSingleLocationDocument).bind(null, variables),
      options
    );
export const OnVoiceTnsNotificationDocument = `
    subscription onVoiceTnsNotification($portalAccountGUID: String!, $portalUserGUID: String!, $accessToken: String!) {
  getTNSObjectNotifications(
    portalAccountGUID: $portalAccountGUID
    portalUserGUID: $portalUserGUID
    accessToken: $accessToken
  ) {
    transactionID
    status
    portalAccountGUID
  }
}
    `;
export const GetVoiceCallGuardDocument = `
    query getVoiceCallGuard($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          callGuard {
            callBlockingDonutGraph {
              onCount
              offCount
              customCount
            }
            callerIdAlertsDonutGraph {
              onCount
              offCount
              customCount
            }
            locations {
              accountNumber
              id
              address {
                friendlyName
                street
                city
                state
              }
              callBlocking
              callerIdAlerts
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceCallGuardQuery = <
      TData = Types.IGetVoiceCallGuardQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVoiceCallGuardQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceCallGuardQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceCallGuardQuery, TError, TData>(
      variables === undefined ? ['getVoiceCallGuard'] : ['getVoiceCallGuard', variables],
      useGraphqlFetcher<Types.IGetVoiceCallGuardQuery, Types.IGetVoiceCallGuardQueryVariables>(GetVoiceCallGuardDocument).bind(null, variables),
      options
    );
export const GetVoiceCallDetailRecordsDocument = `
    query getVoiceCallDetailRecords($portalAccountGUID: String, $accountNumber: String!, $filters: VoiceCdrsFilteringInput!, $limit: Int, $offset: Int, $sort: VoiceCdrSortingInput) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          callDetailRecords(
            accountNumber: $accountNumber
            limit: $limit
            offset: $offset
            sort: $sort
            filters: $filters
          ) {
            accountNumber
            accountAddress {
              friendlyName
              street
              city
              state
            }
            callRecords {
              id
              numberServiceId
              callStartTime
              fromPhoneNumber
              toPhoneNumber
              location
              durationInSecs
              callCharges
              callType
              callPath
            }
            pageInfo {
              totalItems
            }
            filterOptions {
              services
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
              callTypes
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceCallDetailRecordsQuery = <
      TData = Types.IGetVoiceCallDetailRecordsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceCallDetailRecordsQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceCallDetailRecordsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceCallDetailRecordsQuery, TError, TData>(
      ['getVoiceCallDetailRecords', variables],
      useGraphqlFetcher<Types.IGetVoiceCallDetailRecordsQuery, Types.IGetVoiceCallDetailRecordsQueryVariables>(GetVoiceCallDetailRecordsDocument).bind(null, variables),
      options
    );
export const GetVoiceDetailsDocument = `
    query getVoiceDetails($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          details(accountNumber: $accountNumber) {
            accountNumber
            locationId
            serviceType
            cdrServices
            divisionId
            mouSummaries {
              trunkGroupNumber
              isMain
              planMou {
                planUsageInMins
                planAvailInMins
              }
              sharedMou {
                parentId
                thisAccountInMins
                otherAccountsInMins
                availableInMins
              }
            }
            address {
              friendlyName
              street
              city
              state
            }
            chargeSummary {
              trunk
              tollFree
            }
            usageCycle {
              startDate
              endDate
            }
            didInventory {
              serviceTNs {
                serviceType
                servicePhone
              }
              lineTNs
            }
            features {
              alternateRoutingStatus {
                isActive
                phone
              }
              trunkOverflowStatus {
                isActive
                phone
              }
              didOverflowStatus {
                activeCount
                inactiveCount
              }
              callGuardSingleLocationStatus {
                voiceAccountId
                callBlockingDonutGraph {
                  offCount
                  onCount
                }
                callerIdAlertsDonutGraph {
                  onCount
                  offCount
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceDetailsQuery = <
      TData = Types.IGetVoiceDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceDetailsQuery, TError, TData>(
      ['getVoiceDetails', variables],
      useGraphqlFetcher<Types.IGetVoiceDetailsQuery, Types.IGetVoiceDetailsQueryVariables>(GetVoiceDetailsDocument).bind(null, variables),
      options
    );
export const GetVoiceDetailsDidInventoryDocument = `
    query getVoiceDetailsDidInventory($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          details(accountNumber: $accountNumber) {
            didInventory {
              serviceTNs {
                serviceType
                servicePhone
              }
              lineTNs
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceDetailsDidInventoryQuery = <
      TData = Types.IGetVoiceDetailsDidInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceDetailsDidInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceDetailsDidInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceDetailsDidInventoryQuery, TError, TData>(
      ['getVoiceDetailsDidInventory', variables],
      useGraphqlFetcher<Types.IGetVoiceDetailsDidInventoryQuery, Types.IGetVoiceDetailsDidInventoryQueryVariables>(GetVoiceDetailsDidInventoryDocument).bind(null, variables),
      options
    );
export const GetVoiceDidOverflowDocument = `
    query getVoiceDIDOverflow($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          didOverflow(accountNumber: $accountNumber) {
            accountNumber
            address {
              friendlyName
              street
              city
              state
            }
            features {
              alternateRoutingStatus {
                isActive
                phone
              }
              trunkOverflowStatus {
                isActive
                phone
              }
              didOverflowStatus {
                activeCount
                inactiveCount
              }
            }
            didOverflowNumbers {
              didNumber
              routingPhoneNumber
              isOn
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceDidOverflowQuery = <
      TData = Types.IGetVoiceDidOverflowQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceDidOverflowQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceDidOverflowQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceDidOverflowQuery, TError, TData>(
      ['getVoiceDIDOverflow', variables],
      useGraphqlFetcher<Types.IGetVoiceDidOverflowQuery, Types.IGetVoiceDidOverflowQueryVariables>(GetVoiceDidOverflowDocument).bind(null, variables),
      options
    );
export const GetVoiceInventoryCircuitsDocument = `
    query getVoiceInventoryCircuits($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          circuits {
            id
            address {
              friendlyName
              street
              city
              state
            }
            service
            bandwidth
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceInventoryCircuitsQuery = <
      TData = Types.IGetVoiceInventoryCircuitsQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVoiceInventoryCircuitsQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceInventoryCircuitsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceInventoryCircuitsQuery, TError, TData>(
      variables === undefined ? ['getVoiceInventoryCircuits'] : ['getVoiceInventoryCircuits', variables],
      useGraphqlFetcher<Types.IGetVoiceInventoryCircuitsQuery, Types.IGetVoiceInventoryCircuitsQueryVariables>(GetVoiceInventoryCircuitsDocument).bind(null, variables),
      options
    );
export const GetVoiceInventoryDocument = `
    query getVoiceInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          inventory {
            locations {
              id
              accountNumber
              isLegacyDivision
              address {
                friendlyName
                street
                city
                state
              }
            }
            hasVoiceCircuits
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceInventoryQuery = <
      TData = Types.IGetVoiceInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetVoiceInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceInventoryQuery, TError, TData>(
      variables === undefined ? ['getVoiceInventory'] : ['getVoiceInventory', variables],
      useGraphqlFetcher<Types.IGetVoiceInventoryQuery, Types.IGetVoiceInventoryQueryVariables>(GetVoiceInventoryDocument).bind(null, variables),
      options
    );
export const GetVoicePlanDetailsDocument = `
    query getVoicePlanDetails($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          planDetails(accountNumber: $accountNumber) {
            accountNumber
            callerIdName
            callPaths
            tollFreeService
            address {
              friendlyName
              street
              city
              state
            }
            blockNumbers {
              outbound
            }
            longDistanceCarriers {
              local
              primary
              international
            }
            trunkPackages {
              trunkGroupNumber
              didInUseInventory
              maxInclusiveMins
              packageNames {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoicePlanDetailsQuery = <
      TData = Types.IGetVoicePlanDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoicePlanDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetVoicePlanDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoicePlanDetailsQuery, TError, TData>(
      ['getVoicePlanDetails', variables],
      useGraphqlFetcher<Types.IGetVoicePlanDetailsQuery, Types.IGetVoicePlanDetailsQueryVariables>(GetVoicePlanDetailsDocument).bind(null, variables),
      options
    );
export const CreateBlockedCallReportDocument = `
    mutation createBlockedCallReport($addBlockedCallsRequest: AddBlockedCallsRequest!) {
  addBlockedCalls(addBlockedCallsRequest: $addBlockedCallsRequest) {
    code
    message
  }
}
    `;
export const useCreateBlockedCallReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateBlockedCallReportMutation, TError, Types.ICreateBlockedCallReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateBlockedCallReportMutation, TError, Types.ICreateBlockedCallReportMutationVariables, TContext>(
      ['createBlockedCallReport'],
      useGraphqlFetcher<Types.ICreateBlockedCallReportMutation, Types.ICreateBlockedCallReportMutationVariables>(CreateBlockedCallReportDocument),
      options
    );
export const CreateVoiceCdrReportDocument = `
    mutation createVoiceCdrReport($billingAccountNumber: String!, $portalAccountGUID: String!, $cdrParams: CdrReportParametersInput!) {
  putCdrReport(
    billingAccountNumber: $billingAccountNumber
    portalAccountGUID: $portalAccountGUID
    cdrParams: $cdrParams
  ) {
    reportId
    reportName
    reportStatus
    runStartDateTime
    runEndDateTime
    expirationDateTime
    runTime
    createdDate
    portalUserGUID
    parameters {
      serviceType
      phoneNumber
      accountServiceId
      fromDate
      toDate
      reportId
      filename
      orderBy
      sortOrder
    }
  }
}
    `;
export const useCreateVoiceCdrReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateVoiceCdrReportMutation, TError, Types.ICreateVoiceCdrReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateVoiceCdrReportMutation, TError, Types.ICreateVoiceCdrReportMutationVariables, TContext>(
      ['createVoiceCdrReport'],
      useGraphqlFetcher<Types.ICreateVoiceCdrReportMutation, Types.ICreateVoiceCdrReportMutationVariables>(CreateVoiceCdrReportDocument),
      options
    );
export const CreateVoiceDayOfWeekReportDocument = `
    mutation createVoiceDayOfWeekReport($billingAccountNumber: String!, $portalAccountGUID: String!, $dowReport: DayOfWeekReportParametersInput!) {
  putDayOfWeekReport(
    billingAccountNumber: $billingAccountNumber
    portalAccountGUID: $portalAccountGUID
    dowReport: $dowReport
  ) {
    reportId
    reportName
    reportStatus
    runStartDateTime
    runEndDateTime
    expirationDateTime
    runTime
    createdDate
    portalUserGUID
    parameters {
      serviceType
      phoneNumber
      accountServiceId
      fromDate
      toDate
      reportId
      filename
      callFeature
      callCategory
    }
  }
}
    `;
export const useCreateVoiceDayOfWeekReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateVoiceDayOfWeekReportMutation, TError, Types.ICreateVoiceDayOfWeekReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateVoiceDayOfWeekReportMutation, TError, Types.ICreateVoiceDayOfWeekReportMutationVariables, TContext>(
      ['createVoiceDayOfWeekReport'],
      useGraphqlFetcher<Types.ICreateVoiceDayOfWeekReportMutation, Types.ICreateVoiceDayOfWeekReportMutationVariables>(CreateVoiceDayOfWeekReportDocument),
      options
    );
export const GetVoiceCallBlockingReportInfoDocument = `
    query getVoiceCallBlockingReportInfo($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          details(accountNumber: $accountNumber) {
            accountNumber
            locationId
            address {
              friendlyName
              street
              city
              state
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceCallBlockingReportInfoQuery = <
      TData = Types.IGetVoiceCallBlockingReportInfoQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceCallBlockingReportInfoQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceCallBlockingReportInfoQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceCallBlockingReportInfoQuery, TError, TData>(
      ['getVoiceCallBlockingReportInfo', variables],
      useGraphqlFetcher<Types.IGetVoiceCallBlockingReportInfoQuery, Types.IGetVoiceCallBlockingReportInfoQueryVariables>(GetVoiceCallBlockingReportInfoDocument).bind(null, variables),
      options
    );
export const GetVoiceReportInfoDocument = `
    query getVoiceReportInfo($portalAccountGUID: String, $accountNumber: String!, $filters: VoiceReportInfoFilteringInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          reportInfo(accountNumber: $accountNumber, filters: $filters) {
            accountNumber
            accountAddress {
              friendlyName
              street
              city
              state
              zip
            }
            trunk {
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
            }
            tollFree {
              accountServiceTns {
                numberServiceId
                phoneNumber
              }
              usageCycles {
                startDate
                endDate
              }
            }
            managedFeatures {
              hasTrunkOverflow
              hasAlternateRouting
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceReportInfoQuery = <
      TData = Types.IGetVoiceReportInfoQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceReportInfoQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceReportInfoQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceReportInfoQuery, TError, TData>(
      ['getVoiceReportInfo', variables],
      useGraphqlFetcher<Types.IGetVoiceReportInfoQuery, Types.IGetVoiceReportInfoQueryVariables>(GetVoiceReportInfoDocument).bind(null, variables),
      options
    );
export const CreateVoiceTimeOfDayReportDocument = `
    mutation createVoiceTimeOfDayReport($billingAccountNumber: String!, $portalAccountGUID: String!, $todReport: TimeOfDayReportParametersInput!) {
  putTimeOfDayReport(
    billingAccountNumber: $billingAccountNumber
    portalAccountGUID: $portalAccountGUID
    todReport: $todReport
  ) {
    reportId
    reportName
    reportStatus
    runStartDateTime
    runEndDateTime
    expirationDateTime
    runTime
    createdDate
    portalUserGUID
    parameters {
      serviceType
      accountServiceId
      phoneNumber
      date
      reportId
      filename
    }
  }
}
    `;
export const useCreateVoiceTimeOfDayReportMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.ICreateVoiceTimeOfDayReportMutation, TError, Types.ICreateVoiceTimeOfDayReportMutationVariables, TContext>) =>
    useMutation<Types.ICreateVoiceTimeOfDayReportMutation, TError, Types.ICreateVoiceTimeOfDayReportMutationVariables, TContext>(
      ['createVoiceTimeOfDayReport'],
      useGraphqlFetcher<Types.ICreateVoiceTimeOfDayReportMutation, Types.ICreateVoiceTimeOfDayReportMutationVariables>(CreateVoiceTimeOfDayReportDocument),
      options
    );
export const UpdateVoiceTrunkAltRouteDocument = `
    mutation updateVoiceTrunkAltRoute($portalAccountGUID: String!, $trunkAltRouteServiceBody: UpdateTrunkAltRouteServiceRequestBody) {
  updateTrunkAltRoute(
    portalAccountGUID: $portalAccountGUID
    trunkAltRouteServiceBody: $trunkAltRouteServiceBody
  ) {
    action
    clientId
    respCode
    respDescription
    updateResultDetails {
      number
      respCode
      respDescription
    }
  }
}
    `;
export const useUpdateVoiceTrunkAltRouteMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateVoiceTrunkAltRouteMutation, TError, Types.IUpdateVoiceTrunkAltRouteMutationVariables, TContext>) =>
    useMutation<Types.IUpdateVoiceTrunkAltRouteMutation, TError, Types.IUpdateVoiceTrunkAltRouteMutationVariables, TContext>(
      ['updateVoiceTrunkAltRoute'],
      useGraphqlFetcher<Types.IUpdateVoiceTrunkAltRouteMutation, Types.IUpdateVoiceTrunkAltRouteMutationVariables>(UpdateVoiceTrunkAltRouteDocument),
      options
    );
export const UpdateVoiceTrunkOverflowDocument = `
    mutation updateVoiceTrunkOverflow($portalAccountGUID: String!, $trunkOverflowBody: UpdateTrunkOverflowServiceRequestBody) {
  updateTrunkOverflow(
    portalAccountGUID: $portalAccountGUID
    trunkOverflowBody: $trunkOverflowBody
  ) {
    action
    clientId
    respCode
    respDescription
    updateResultDetails {
      number
      respCode
      respDescription
    }
  }
}
    `;
export const useUpdateVoiceTrunkOverflowMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateVoiceTrunkOverflowMutation, TError, Types.IUpdateVoiceTrunkOverflowMutationVariables, TContext>) =>
    useMutation<Types.IUpdateVoiceTrunkOverflowMutation, TError, Types.IUpdateVoiceTrunkOverflowMutationVariables, TContext>(
      ['updateVoiceTrunkOverflow'],
      useGraphqlFetcher<Types.IUpdateVoiceTrunkOverflowMutation, Types.IUpdateVoiceTrunkOverflowMutationVariables>(UpdateVoiceTrunkOverflowDocument),
      options
    );
export const GetVoiceSharedMouDocument = `
    query getVoiceSharedMOU($portalAccountGUID: String, $accountNumber: String!, $packageParentId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        voice {
          smouPackageDetails(
            accountNumber: $accountNumber
            packageParentId: $packageParentId
          ) {
            summary {
              details {
                packageInclusiveMins
                totalAccounts
              }
              totalPackageUsage {
                used
                available
              }
            }
            sharedAccounts {
              accountNumber
              usage
              address {
                friendlyName
                street
                city
                state
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetVoiceSharedMouQuery = <
      TData = Types.IGetVoiceSharedMouQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVoiceSharedMouQueryVariables,
      options?: UseQueryOptions<Types.IGetVoiceSharedMouQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVoiceSharedMouQuery, TError, TData>(
      ['getVoiceSharedMOU', variables],
      useGraphqlFetcher<Types.IGetVoiceSharedMouQuery, Types.IGetVoiceSharedMouQueryVariables>(GetVoiceSharedMouDocument).bind(null, variables),
      options
    );
export const UpdateVoiceDidOverflowDocument = `
    mutation updateVoiceDIDOverflow($portalAccountGUID: String!, $trunkOverflowBody: UpdateTrunkOverflowServiceRequestBody!) {
  updateDIDOverflow(
    portalAccountGUID: $portalAccountGUID
    trunkOverflowBody: $trunkOverflowBody
  ) {
    action
    clientId
    respCode
    respDescription
    updateResultDetails {
      number
      respCode
      respDescription
    }
  }
}
    `;
export const useUpdateVoiceDidOverflowMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IUpdateVoiceDidOverflowMutation, TError, Types.IUpdateVoiceDidOverflowMutationVariables, TContext>) =>
    useMutation<Types.IUpdateVoiceDidOverflowMutation, TError, Types.IUpdateVoiceDidOverflowMutationVariables, TContext>(
      ['updateVoiceDIDOverflow'],
      useGraphqlFetcher<Types.IUpdateVoiceDidOverflowMutation, Types.IUpdateVoiceDidOverflowMutationVariables>(UpdateVoiceDidOverflowDocument),
      options
    );
export const GetWavelengthInventoryDocument = `
    query getWavelengthInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wavelength {
          inventory {
            networks {
              id
              locations {
                id
                address {
                  friendlyName
                  street
                  city
                  state
                }
                isHub
                circuit {
                  id
                  bandwidth
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetWavelengthInventoryQuery = <
      TData = Types.IGetWavelengthInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetWavelengthInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetWavelengthInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWavelengthInventoryQuery, TError, TData>(
      variables === undefined ? ['getWavelengthInventory'] : ['getWavelengthInventory', variables],
      useGraphqlFetcher<Types.IGetWavelengthInventoryQuery, Types.IGetWavelengthInventoryQueryVariables>(GetWavelengthInventoryDocument).bind(null, variables),
      options
    );
export const GetWirelessAccountDetailsDocument = `
    query getWirelessAccountDetails($portalAccountGUID: String, $accountNumber: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wireless {
          accountDetails(accountNumber: $accountNumber) {
            accountNumber
            dataPools {
              servicePlan
              usageSummary {
                planUsageInGB
                availableInGB
                usageAlertPct
              }
              devices {
                id
                circuitId
                usageInGB
                address {
                  friendlyName
                  street
                  city
                  state
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetWirelessAccountDetailsQuery = <
      TData = Types.IGetWirelessAccountDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetWirelessAccountDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetWirelessAccountDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWirelessAccountDetailsQuery, TError, TData>(
      ['getWirelessAccountDetails', variables],
      useGraphqlFetcher<Types.IGetWirelessAccountDetailsQuery, Types.IGetWirelessAccountDetailsQueryVariables>(GetWirelessAccountDetailsDocument).bind(null, variables),
      options
    );
export const GetWirelessDeviceDetailsDocument = `
    query getWirelessDeviceDetails($portalAccountGUID: String, $accountNumber: String!, $deviceId: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wireless {
          deviceDetails(accountNumber: $accountNumber, deviceId: $deviceId) {
            accountNumber
            name
            circuitId
            servicePlan
            usageSummary {
              deviceUsageInGB
              planUsageInGB
              availableInGB
            }
            address {
              friendlyName
              street
              city
              state
            }
            backupForFiber {
              circuitId
              bandwidth
              backup
              security
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetWirelessDeviceDetailsQuery = <
      TData = Types.IGetWirelessDeviceDetailsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetWirelessDeviceDetailsQueryVariables,
      options?: UseQueryOptions<Types.IGetWirelessDeviceDetailsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWirelessDeviceDetailsQuery, TError, TData>(
      ['getWirelessDeviceDetails', variables],
      useGraphqlFetcher<Types.IGetWirelessDeviceDetailsQuery, Types.IGetWirelessDeviceDetailsQueryVariables>(GetWirelessDeviceDetailsDocument).bind(null, variables),
      options
    );
export const GetWirelessInventoryDocument = `
    query getWirelessInventory($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wireless {
          inventory {
            accounts {
              accountNumber
              dataPools {
                id
                servicePlan
                sharedDataInGB
                devices
                usageAlertPct
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetWirelessInventoryQuery = <
      TData = Types.IGetWirelessInventoryQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetWirelessInventoryQueryVariables,
      options?: UseQueryOptions<Types.IGetWirelessInventoryQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWirelessInventoryQuery, TError, TData>(
      variables === undefined ? ['getWirelessInventory'] : ['getWirelessInventory', variables],
      useGraphqlFetcher<Types.IGetWirelessInventoryQuery, Types.IGetWirelessInventoryQueryVariables>(GetWirelessInventoryDocument).bind(null, variables),
      options
    );
export const GetWirelessDailyDeviceUsageDocument = `
    query getWirelessDailyDeviceUsage($portalAccountGUID: String, $deviceId: String!, $dateRange: WirelessDateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wireless {
          dailyDeviceUsage(deviceId: $deviceId, dateRange: $dateRange) {
            date
            usageInGB
          }
        }
      }
    }
  }
}
    `;
export const useGetWirelessDailyDeviceUsageQuery = <
      TData = Types.IGetWirelessDailyDeviceUsageQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetWirelessDailyDeviceUsageQueryVariables,
      options?: UseQueryOptions<Types.IGetWirelessDailyDeviceUsageQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWirelessDailyDeviceUsageQuery, TError, TData>(
      ['getWirelessDailyDeviceUsage', variables],
      useGraphqlFetcher<Types.IGetWirelessDailyDeviceUsageQuery, Types.IGetWirelessDailyDeviceUsageQueryVariables>(GetWirelessDailyDeviceUsageDocument).bind(null, variables),
      options
    );
export const GetWirelessDailyPlanUsageDocument = `
    query getWirelessDailyPlanUsage($portalAccountGUID: String, $accountNumber: String!, $servicePlan: String!, $dateRange: WirelessDateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        wireless {
          dailyPlanUsage(
            accountNumber: $accountNumber
            servicePlan: $servicePlan
            dateRange: $dateRange
          ) {
            date
            usageInGB
          }
        }
      }
    }
  }
}
    `;
export const useGetWirelessDailyPlanUsageQuery = <
      TData = Types.IGetWirelessDailyPlanUsageQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetWirelessDailyPlanUsageQueryVariables,
      options?: UseQueryOptions<Types.IGetWirelessDailyPlanUsageQuery, TError, TData>
    ) =>
    useQuery<Types.IGetWirelessDailyPlanUsageQuery, TError, TData>(
      ['getWirelessDailyPlanUsage', variables],
      useGraphqlFetcher<Types.IGetWirelessDailyPlanUsageQuery, Types.IGetWirelessDailyPlanUsageQueryVariables>(GetWirelessDailyPlanUsageDocument).bind(null, variables),
      options
    );
export const AimLegacyIdentityDocument = `
    query aimLegacyIdentity {
  legacyIdentity {
    token
  }
}
    `;
export const useAimLegacyIdentityQuery = <
      TData = Types.IAimLegacyIdentityQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimLegacyIdentityQueryVariables,
      options?: UseQueryOptions<Types.IAimLegacyIdentityQuery, TError, TData>
    ) =>
    useQuery<Types.IAimLegacyIdentityQuery, TError, TData>(
      variables === undefined ? ['aimLegacyIdentity'] : ['aimLegacyIdentity', variables],
      useGraphqlFetcher<Types.IAimLegacyIdentityQuery, Types.IAimLegacyIdentityQueryVariables>(AimLegacyIdentityDocument).bind(null, variables),
      options
    );
export const GetCircuitMetricsDocument = `
    query getCircuitMetrics($portalAccountGUID: String, $circuitId: String!, $metricType: MetricsTypeInput!, $dateRange: DateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        metrics {
          circuit(circuitId: $circuitId, metricType: $metricType, dateRange: $dateRange) {
            timestamp
            upstream
            downstream
          }
        }
      }
    }
  }
}
    `;
export const useGetCircuitMetricsQuery = <
      TData = Types.IGetCircuitMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetCircuitMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetCircuitMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetCircuitMetricsQuery, TError, TData>(
      ['getCircuitMetrics', variables],
      useGraphqlFetcher<Types.IGetCircuitMetricsQuery, Types.IGetCircuitMetricsQueryVariables>(GetCircuitMetricsDocument).bind(null, variables),
      options
    );
export const GetEquipmentMetricsDocument = `
    query getEquipmentMetrics($portalAccountGUID: String, $circuitId: String!, $dateRange: DateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        metrics {
          equipment(circuitId: $circuitId, dateRange: $dateRange) {
            routers {
              id
              isOnline
              reachability {
                timestamp
                reachabilityPct
              }
            }
            switches {
              id
              isOnline
              reachability {
                timestamp
                reachabilityPct
              }
            }
            networkIntDevices {
              id
              isOnline
              reachability {
                timestamp
                reachabilityPct
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEquipmentMetricsQuery = <
      TData = Types.IGetEquipmentMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetEquipmentMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetEquipmentMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetEquipmentMetricsQuery, TError, TData>(
      ['getEquipmentMetrics', variables],
      useGraphqlFetcher<Types.IGetEquipmentMetricsQuery, Types.IGetEquipmentMetricsQueryVariables>(GetEquipmentMetricsDocument).bind(null, variables),
      options
    );
export const GetNsgMetricsDocument = `
    query getNsgMetrics($portalAccountGUID: String, $edgeName: String!, $routeTo: String!, $dateRange: DateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        metrics {
          nsgMetrics(edgeName: $edgeName, routeTo: $routeTo, dateRange: $dateRange) {
            timestamp
            loss
            latency
            jitter
          }
        }
      }
    }
  }
}
    `;
export const useGetNsgMetricsQuery = <
      TData = Types.IGetNsgMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetNsgMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetNsgMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetNsgMetricsQuery, TError, TData>(
      ['getNsgMetrics', variables],
      useGraphqlFetcher<Types.IGetNsgMetricsQuery, Types.IGetNsgMetricsQueryVariables>(GetNsgMetricsDocument).bind(null, variables),
      options
    );
export const GetPeakUtilizationMetricsDocument = `
    query getPeakUtilizationMetrics($portalAccountGUID: String, $circuitId: String!, $bandwidth: String!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        metrics {
          peakUtilization(circuitId: $circuitId, bandWidth: $bandwidth) {
            currentDay {
              upstream
              downstream
            }
            pastWeek {
              upstream
              downstream
            }
            pastMonth {
              upstream
              downstream
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetPeakUtilizationMetricsQuery = <
      TData = Types.IGetPeakUtilizationMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetPeakUtilizationMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetPeakUtilizationMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetPeakUtilizationMetricsQuery, TError, TData>(
      ['getPeakUtilizationMetrics', variables],
      useGraphqlFetcher<Types.IGetPeakUtilizationMetricsQuery, Types.IGetPeakUtilizationMetricsQueryVariables>(GetPeakUtilizationMetricsDocument).bind(null, variables),
      options
    );
export const GetVLanMetricsDocument = `
    query getVLanMetrics($portalAccountGUID: String, $vPortIds: [String!]!, $dateRange: DateRangeInput!) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      services {
        metrics {
          vLanMetrics(vPortIds: $vPortIds, dateRange: $dateRange) {
            timestamp
            upstream
            downstream
          }
        }
      }
    }
  }
}
    `;
export const useGetVLanMetricsQuery = <
      TData = Types.IGetVLanMetricsQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetVLanMetricsQueryVariables,
      options?: UseQueryOptions<Types.IGetVLanMetricsQuery, TError, TData>
    ) =>
    useQuery<Types.IGetVLanMetricsQuery, TError, TData>(
      ['getVLanMetrics', variables],
      useGraphqlFetcher<Types.IGetVLanMetricsQuery, Types.IGetVLanMetricsQueryVariables>(GetVLanMetricsDocument).bind(null, variables),
      options
    );
export const AimOrganizationByIdDocument = `
    query aimOrganizationById($organizationId: String) {
  organizationById(organizationId: $organizationId) {
    id
    name
    supportPin
    accountType {
      id
      name
    }
    availableServices {
      id
    }
    queryKeys {
      id
      name
      keyData
    }
    features {
      id
      isEnabled
      name
    }
    enterpriseSupportContacts {
      id
      name
      phoneNumber
      isPremierSupport
    }
    accountOrganizationContacts {
      id
      contactType
      firstName
      lastName
      phoneNumber
      email
    }
    features {
      id
      name
      isSpecialFeature
      isEnabled
    }
  }
}
    `;
export const useAimOrganizationByIdQuery = <
      TData = Types.IAimOrganizationByIdQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimOrganizationByIdQueryVariables,
      options?: UseQueryOptions<Types.IAimOrganizationByIdQuery, TError, TData>
    ) =>
    useQuery<Types.IAimOrganizationByIdQuery, TError, TData>(
      variables === undefined ? ['aimOrganizationById'] : ['aimOrganizationById', variables],
      useGraphqlFetcher<Types.IAimOrganizationByIdQuery, Types.IAimOrganizationByIdQueryVariables>(AimOrganizationByIdDocument).bind(null, variables),
      options
    );
export const GetOrgServicesDocument = `
    query getOrgServices($portalAccountGUID: String) {
  user(portalAccountGUID: $portalAccountGUID) {
    organization {
      billing {
        hasBills
      }
      settings {
        hasManageLocationNames
      }
      locations {
        hasLocations
      }
      services {
        fiber {
          hasFiber
        }
        enterpriseNetworkEdge {
          hasEne
        }
        wavelength {
          hasWavelength
        }
        unifiedComms {
          hasUnifiedComms
        }
        network {
          hasNetwork
        }
        businessInternet {
          hasBusinessInternet
        }
        businessVoice {
          hasBusinessVoice
        }
        video {
          hasVideo
        }
        tv {
          hasTv
        }
        wireless {
          hasWireless
        }
        managedSecurity {
          hasManagedSecurity
        }
        tickets {
          hasTickets
        }
        managedNetworkEdge {
          hasMne
        }
        voice {
          hasVoice
        }
        reports {
          hasReporting
        }
        storage {
          hasStorage
        }
        cloudSecurity {
          hasCloudSecurity
        }
        secureAccess {
          hasSecureAccess
        }
        managedRouterService {
          hasMrs
        }
        secureInternet {
          hasSecureInternet
        }
      }
    }
  }
}
    `;
export const useGetOrgServicesQuery = <
      TData = Types.IGetOrgServicesQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IGetOrgServicesQueryVariables,
      options?: UseQueryOptions<Types.IGetOrgServicesQuery, TError, TData>
    ) =>
    useQuery<Types.IGetOrgServicesQuery, TError, TData>(
      variables === undefined ? ['getOrgServices'] : ['getOrgServices', variables],
      useGraphqlFetcher<Types.IGetOrgServicesQuery, Types.IGetOrgServicesQueryVariables>(GetOrgServicesDocument).bind(null, variables),
      options
    );
export const GetSsoUrlDocument = `
    query getSsoUrl($input: GetSSOUrlInput!) {
  getSsoUrl(input: $input) {
    url
    message
  }
}
    `;
export const useGetSsoUrlQuery = <
      TData = Types.IGetSsoUrlQuery,
      TError = Types.IGqlError[]
    >(
      variables: Types.IGetSsoUrlQueryVariables,
      options?: UseQueryOptions<Types.IGetSsoUrlQuery, TError, TData>
    ) =>
    useQuery<Types.IGetSsoUrlQuery, TError, TData>(
      ['getSsoUrl', variables],
      useGraphqlFetcher<Types.IGetSsoUrlQuery, Types.IGetSsoUrlQueryVariables>(GetSsoUrlDocument).bind(null, variables),
      options
    );
export const AimAllCapabilitiesForUserDocument = `
    query aimAllCapabilitiesForUser {
  allCapabilitiesForUser {
    userId
    isInternalUser
    capabilities {
      name
      id
    }
  }
}
    `;
export const useAimAllCapabilitiesForUserQuery = <
      TData = Types.IAimAllCapabilitiesForUserQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimAllCapabilitiesForUserQueryVariables,
      options?: UseQueryOptions<Types.IAimAllCapabilitiesForUserQuery, TError, TData>
    ) =>
    useQuery<Types.IAimAllCapabilitiesForUserQuery, TError, TData>(
      variables === undefined ? ['aimAllCapabilitiesForUser'] : ['aimAllCapabilitiesForUser', variables],
      useGraphqlFetcher<Types.IAimAllCapabilitiesForUserQuery, Types.IAimAllCapabilitiesForUserQueryVariables>(AimAllCapabilitiesForUserDocument).bind(null, variables),
      options
    );
export const FetchAnonymousTokenDocument = `
    mutation fetchAnonymousToken($clientName: String!) {
  fetchAnonymousToken(clientName: $clientName) {
    code
    message
    data {
      resultCode
      resultCodeName
      resultMessage
      token_type
      access_token
      expires_in
    }
  }
}
    `;
export const useFetchAnonymousTokenMutation = <
      TError = Types.IGqlError[],
      TContext = unknown
    >(options?: UseMutationOptions<Types.IFetchAnonymousTokenMutation, TError, Types.IFetchAnonymousTokenMutationVariables, TContext>) =>
    useMutation<Types.IFetchAnonymousTokenMutation, TError, Types.IFetchAnonymousTokenMutationVariables, TContext>(
      ['fetchAnonymousToken'],
      useGraphqlFetcher<Types.IFetchAnonymousTokenMutation, Types.IFetchAnonymousTokenMutationVariables>(FetchAnonymousTokenDocument),
      options
    );
export const AimSelfDocument = `
    query aimSelf {
  self {
    id
    firstName
    lastName
    email
    phone
    pid
    isInternalUser
    parentOrganization {
      id
      name
      supportPin
      accountType {
        id
        name
      }
      queryKeys {
        id
        name
        keyData
      }
      features {
        id
        isEnabled
        name
      }
      enterpriseSupportContacts {
        id
        name
        phoneNumber
        isPremierSupport
      }
      accountOrganizationContacts {
        id
        contactType
        firstName
        lastName
        phoneNumber
        email
      }
      availableServices {
        id
        name
      }
    }
    organizations {
      id
      name
      supportPin
      accountType {
        id
        name
      }
      queryKeys {
        id
        name
        keyData
      }
      features {
        id
        isEnabled
        name
      }
      enterpriseSupportContacts {
        id
        name
        phoneNumber
        isPremierSupport
      }
      accountOrganizationContacts {
        id
        contactType
        firstName
        lastName
        phoneNumber
        email
      }
      availableServices {
        id
        name
      }
    }
  }
}
    `;
export const useAimSelfQuery = <
      TData = Types.IAimSelfQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimSelfQueryVariables,
      options?: UseQueryOptions<Types.IAimSelfQuery, TError, TData>
    ) =>
    useQuery<Types.IAimSelfQuery, TError, TData>(
      variables === undefined ? ['aimSelf'] : ['aimSelf', variables],
      useGraphqlFetcher<Types.IAimSelfQuery, Types.IAimSelfQueryVariables>(AimSelfDocument).bind(null, variables),
      options
    );
export const AimSelfAuthorizeDocument = `
    query aimSelfAuthorize($organizationId: String) {
  selfAuthorize(organizationId: $organizationId) {
    id
    firstName
    lastName
    email
    phone
    pid
    isInternalUser
    authorizedOrganization {
      id
      name
      supportPin
      accountType {
        id
        name
      }
      queryKeys {
        id
        name
        keyData
      }
      enterpriseSupportContacts {
        id
        name
        phoneNumber
        isPremierSupport
      }
      accountOrganizationContacts {
        id
        contactType
        firstName
        lastName
        phoneNumber
        email
      }
    }
    authorizedOrganizationServices {
      id
      name
      description
      capabilities {
        id
        name
      }
    }
  }
}
    `;
export const useAimSelfAuthorizeQuery = <
      TData = Types.IAimSelfAuthorizeQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimSelfAuthorizeQueryVariables,
      options?: UseQueryOptions<Types.IAimSelfAuthorizeQuery, TError, TData>
    ) =>
    useQuery<Types.IAimSelfAuthorizeQuery, TError, TData>(
      variables === undefined ? ['aimSelfAuthorize'] : ['aimSelfAuthorize', variables],
      useGraphqlFetcher<Types.IAimSelfAuthorizeQuery, Types.IAimSelfAuthorizeQueryVariables>(AimSelfAuthorizeDocument).bind(null, variables),
      options
    );
export const AimUserByIdDocument = `
    query aimUserById($idOrPID: String) {
  userById(idOrPID: $idOrPID) {
    id
    firstName
    lastName
    email
    pid
    phone
    isInternalUser
    accessPolicyIds
    organizationIds
    isSyncManaged
  }
}
    `;
export const useAimUserByIdQuery = <
      TData = Types.IAimUserByIdQuery,
      TError = Types.IGqlError[]
    >(
      variables?: Types.IAimUserByIdQueryVariables,
      options?: UseQueryOptions<Types.IAimUserByIdQuery, TError, TData>
    ) =>
    useQuery<Types.IAimUserByIdQuery, TError, TData>(
      variables === undefined ? ['aimUserById'] : ['aimUserById', variables],
      useGraphqlFetcher<Types.IAimUserByIdQuery, Types.IAimUserByIdQueryVariables>(AimUserByIdDocument).bind(null, variables),
      options
    );