/* eslint-disable consistent-return */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import dayjs from 'dayjs';
import { QueryClient } from '@tanstack/react-query';
import {
  Maybe,
  NetworkReportAggregateType,
  NetworkReportMetricType,
} from '../types/types.generated';
import {
  IReportFormData,
  ReportFormNetworkMetricType,
} from '../types/reportTypes';
import { today } from './datePickerUtils';

// Maps the metric types as per mutation schema
export const mapMetricTypes = (selectedMetrics: { [key: string]: boolean }) => {
  const mappedMetricTypes = [];
  if (selectedMetrics.utilization) {
    mappedMetricTypes.push(NetworkReportMetricType.Utilization);
  }
  if (selectedMetrics.serviceAvail) {
    mappedMetricTypes.push(NetworkReportMetricType.ServiceAvailability);
  }
  if (selectedMetrics.frameDelayRT) {
    mappedMetricTypes.push(NetworkReportMetricType.MedianFrameDelay);
  }
  if (selectedMetrics.frameDelayV) {
    mappedMetricTypes.push(NetworkReportMetricType.FrameDelayVariation);
  }
  if (selectedMetrics.frameLossRatio) {
    mappedMetricTypes.push(NetworkReportMetricType.FrameLossRatio);
  }
  return mappedMetricTypes;
};

// Changes the aggregate type to what's required by the BE
export const mapAggregateType = (timeIncrement: string) => {
  let aggregateType: NetworkReportAggregateType;
  switch (timeIncrement) {
    case 'day':
      aggregateType = NetworkReportAggregateType.Day;
      break;
    case 'fifteen':
      aggregateType = NetworkReportAggregateType.Minute;
      break;
    case 'hour':
      aggregateType = NetworkReportAggregateType.Hour;
      break;
    default:
      aggregateType = NetworkReportAggregateType.Day;
      break;
  }
  return aggregateType;
};

/**
 * Formats the dates for creating a report in the required way
 * Backend needs them for the mutation
 */
export const formatReportDateRange = (
  startDate: Maybe<Date>,
  endDate: Maybe<Date>
): { startDateTime: string; endDateTime: string } => {
  return {
    startDateTime: startDate
      ? dayjs(startDate).format('YYYY-MM-DDT00:00:00')
      : '',
    endDateTime: endDate ? dayjs(endDate).format('YYYY-MM-DDT23:59:59') : '',
  };
};

export const invalidateAssociatedQueries = (config: {
  queryClient: QueryClient;
  queryKeys: string[];
}) => {
  const { queryClient, queryKeys } = config;
  queryKeys.forEach((key) => {
    queryClient
      .invalidateQueries([key], {
        refetchType: 'inactive',
      })
      .catch(() => {});
  });
};

export const generateMinDate = (
  formData: IReportFormData,
  isStaticMinDate: boolean
) => {
  if (!isStaticMinDate) {
    const { timeIncrement } = formData;
    if (timeIncrement === 'day') {
      return new Date(dayjs(today).subtract(365, 'day').format());
    }
    if (timeIncrement === 'hour') {
      return new Date(dayjs(today).subtract(90, 'day').format());
    }
    if (timeIncrement === 'fifteen') {
      return new Date(dayjs(today).subtract(31, 'day').format());
    }
    if (timeIncrement === '30 days') {
      return new Date(dayjs(today).subtract(29, 'day').format());
    }
    if (timeIncrement === '45 days') {
      return new Date(dayjs(today).subtract(45, 'day').format());
    }
  }
  const min = 5;
  if (
    dayjs(formData.dateRange?.startDate).isBefore(
      dayjs(today).subtract(min - 1, 'month')
    )
  ) {
    return new Date(
      dayjs(formData.dateRange?.startDate).startOf('month').format()
    );
  }
  return new Date(
    dayjs(today)
      .subtract(min - 1, 'month')
      .format()
  );
};

export const generateMaxRange = (
  formData: IReportFormData,
  isStaticMinDate: boolean
) => {
  if (!isStaticMinDate) {
    const { timeIncrement, reportMetric } = formData;
    if (timeIncrement === 'day') {
      return 0;
    }
    if (timeIncrement === 'hour') {
      return 31;
    }

    if (timeIncrement === 'fifteen') {
      return 31;
    }

    if (timeIncrement === '45 days') {
      return 30;
    }
  }
  return 0;
};

export const generateRangeOptions = (
  formData: IReportFormData,
  isStaticMinDate: boolean
) => {
  if (!isStaticMinDate) {
    const { timeIncrement } = formData;
    if (timeIncrement === 'day') {
      return [7, 30, 90];
    }
    if (timeIncrement === 'hour') {
      return [7, 30];
    }
    if (timeIncrement === 'fifteen') {
      return [7, 30];
    }
    if (timeIncrement === '45 days') {
      return [7, 30];
    }
  } else if (!formData.reportMetric) {
    return [7, 30, 90];
  }
};

export const generateFixedRange = (
  reportMetric: ReportFormNetworkMetricType
) => {
  if (reportMetric && reportMetric.includes('Hour')) {
    return 1;
  }
  if (reportMetric && reportMetric.includes('Day')) {
    return 7;
  }
  return undefined;
};
