/**
 * [Feature Toggles](https://martinfowler.com/articles/feature-toggles.html)
 * are a powerful technique, allowing teams to modify system behavior without changing code.
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */

/**
 * FEATURE TOGGLE NAMES ENUM is used to keep track of all names used in the app
 * to avoid duplicates as well as eliminate possible spelling issues.
 *
 * The Feature Toggle naming convention is as follows:
 *
 * DomainPageFeature
 *
 * If the DOMAIN doesn't apply, use 'Misc' for the Miscellaneous Toggles group.
 * The PAGE may not apply, but if it does include it in the name.
 */
export enum FeatureToggleName {
  ArchitectureDataDogRumIntegration = 'ArchitectureDataDogRumIntegration',
  ArchitectureDisableReCaptcha = 'ArchitectureDisableReCaptcha',
  AuthSMBRedirectMessage = 'AuthSMBRedirectMessage',
  AuthSelfRegistrationCircuitId = 'AuthSelfRegistrationCircuitId',
  BillingAutopay = 'BillingAutopay',
  BillingPaymentsSummary = 'BillingPaymentsSummary',
  BillingPreferences = 'BillingPreferences',
  BillingStoredPayments = 'BillingStoredPayments',
  BillingPaymentsHistory = 'BillingPaymentsHistory',
  BusinessInternet = 'BusinessInternet',
  BusinessInternetDetails = 'BusinessInternetDetails',
  BusinessInternetEquipmentRestart = 'BusinessInternetEquipmentRestart',
  BusinessInternetEquipmentStatus = 'BusinessInternetEquipmentStatus',
  BusinessVoice = 'BusinessVoice',
  CloudSecurity = 'CloudSecurity',
  DesignKiteDropDown = 'DesignKiteDropDown',
  EventLogsUseNextQuery = 'EventLogsUseNextQuery',
  ENEDeviceManagement = 'ENEDeviceManagement',
  MNEDeviceManagement = 'MNEDeviceManagement',
  MNEShowSSOSiteAndConferenceManagerPortal = 'MNEShowSSOSiteAndConferenceManagerPortal',
  MRSMetrics = 'MRSMetrics',
  MRSMetricsTrafficByPort = 'MRSMetricsTrafficByPort',
  MiscOrganizationPageLayoutUpdate = 'MiscOrganizationPageLayoutUpdate',
  MiscSupportForms = 'MiscSupportForms',
  NetworkEquipmentReport = 'NetworkEquipmentReport',
  SecureAccess = 'SecureAccess',
  SecureInternet = 'SecureInternet',
  SecureInternetDetails = 'SecureInternetDetails',
  SecureInternetDeviceManagement = 'SecureInternetDeviceManagement',
  TicketHistoryEmails = 'TicketHistoryEmails',
  TvService = 'TvService',
  UCRingCentral = 'UCRingCentral',
  VideoService = 'VideoService',
  VirtualSecurity = 'VirtualSecurity', // TODO: Do we still need this?
  VoiceCallGuard = 'VoiceCallGuard',
  VoiceDidOverflow = 'VoiceDidOverflow',
  WirelessNewServicePlans = 'WirelessNewServicePlans',
  CorrectWirelessOnServiceDetails = 'CorrectWirelessOnServiceDetails',
}

export interface IFeatureToggle {
  readonly name: FeatureToggleName;
  readonly isEnabled: boolean;
}

export interface IFeatureToggles {
  readonly environment: string;
  readonly toggles: IFeatureToggle[];
}

export type IFlatFeatureToggles = {
  [key in FeatureToggleName]: boolean;
};
