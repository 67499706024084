import { FeatureToggleName, IFeatureToggles } from '../featureToggles.types';

/**
 * These are default hard-coded Production toggles.
 * These are fallback values in case any specific Feature Toggle has not been
 * added to the BE list of Feature Toggles through the Dexter app.
 *
 * This is the file used for production, edit with caution!
 * Recommend using lower-env configs for dev & testing as they will extend from this one.
 *
 * Note:
 * The business logic to use different feature toggle strategies depending on the different categories (Release,
 * Operations, Experimental) has been moved to the BE. FE will now only receive a list of Toggle names and
 * corresponding boolean values from the getUserFeatureToggles endpoint which will override the setting in this file.
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs
 */
export const featureTogglesDefault: IFeatureToggles = {
  environment: 'default',
  toggles: [
    // ====== Architecture Domain ======
    {
      name: FeatureToggleName.ArchitectureDataDogRumIntegration,
      isEnabled: true,
    },

    // ====== Auth Domain ======
    {
      name: FeatureToggleName.AuthSelfRegistrationCircuitId,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.AuthSMBRedirectMessage,
      isEnabled: true,
    },

    // ====== Billing Domain ======
    {
      name: FeatureToggleName.BillingAutopay,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BillingPaymentsSummary,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BillingPreferences,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BillingStoredPayments,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BillingPaymentsHistory,
      isEnabled: true,
    },

    // ====== Business Internet Domain ======
    {
      name: FeatureToggleName.BusinessInternet,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BusinessInternetDetails,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BusinessInternetEquipmentStatus,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.BusinessInternetEquipmentRestart,
      isEnabled: true,
    },

    // ====== BusinessVoice Domain ======
    {
      name: FeatureToggleName.BusinessVoice,
      isEnabled: true,
    },

    // ====== CloudSecurity Domain ======
    {
      name: FeatureToggleName.CloudSecurity,
      isEnabled: true,
    },

    // ====== Design Domain ======
    {
      name: FeatureToggleName.DesignKiteDropDown,
      isEnabled: true,
    },

    // ====== Event Logs Page ======
    {
      name: FeatureToggleName.EventLogsUseNextQuery,
      isEnabled: true,
    },

    // ====== ENE Domain ======
    {
      name: FeatureToggleName.ENEDeviceManagement,
      isEnabled: true,
    },

    // ====== MNE Domain ======
    {
      name: FeatureToggleName.MNEDeviceManagement,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.MNEShowSSOSiteAndConferenceManagerPortal,
      isEnabled: true,
    },

    {
      name: FeatureToggleName.CorrectWirelessOnServiceDetails,
      isEnabled: true,
    },

    // ====== MRS Domain ======
    {
      name: FeatureToggleName.MRSMetrics,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.MRSMetricsTrafficByPort,
      isEnabled: true,
    },

    // ====== Miscellaneous Toggles ======
    {
      name: FeatureToggleName.MiscOrganizationPageLayoutUpdate,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.MiscSupportForms,
      isEnabled: true,
    },
    // UC Ring Central & User Invite for Ring Central
    {
      name: FeatureToggleName.UCRingCentral,
      isEnabled: true,
    },

    // ====== Network (Network & Fiber ) Domain ======
    {
      name: FeatureToggleName.NetworkEquipmentReport,
      isEnabled: true,
    },

    // ====== SecureAccess Domain ======
    {
      name: FeatureToggleName.SecureAccess,
      isEnabled: true,
    },

    // ====== Secure Internet Domain ======
    {
      name: FeatureToggleName.SecureInternet,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.SecureInternetDetails,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.SecureInternetDeviceManagement,
      isEnabled: true,
    },

    // ====== Tickets Domain ======
    {
      name: FeatureToggleName.TicketHistoryEmails,
      isEnabled: true,
    },

    // ====== TvService Domain ======
    {
      name: FeatureToggleName.TvService,
      isEnabled: false,
    },

    // ====== VideoService Domain ======
    {
      name: FeatureToggleName.VideoService,
      isEnabled: true,
    },

    // ====== VirtualSecurity Domain ======
    {
      name: FeatureToggleName.VirtualSecurity,
      isEnabled: true,
    },

    // ====== Voice Domain ======
    {
      name: FeatureToggleName.VoiceCallGuard,
      isEnabled: true,
    },
    {
      name: FeatureToggleName.VoiceDidOverflow,
      isEnabled: true,
    },

    // ====== Wireless Domain ======
    {
      name: FeatureToggleName.WirelessNewServicePlans,
      isEnabled: true, // Going out with Upsidedown
    },
  ],
};
