import {
  AppEnv,
  IAppConfig,
  PiTargetBg,
} from '../../config/appConfig/appConfig.types';

// Default data for init of context
const appConfigDefault: IAppConfig = {
  isProd: false,
  isTrueProd: false,
  isMock: false,
  isLocal: false,
  isDemo: false,

  app: {
    shortName: '',
    displayName: '',
    env: AppEnv.Mock,
    version: '',
    versionName: '',
  },

  api: {
    useDirectHost: false,
    useAimDirectHost: false,
    auth: '',
    authAnonymous: '',
    anonymous: '',
    graphql: '',
    restEndpoint: '',
    cxdo: '',
    sso: '',
    wsVoiceCallGuardUpdatesHost: '',
    wsBusinessInternetRestartUpdatesHost: '',
    video: '',
    ravenHost: '',
  },

  dataDog: {
    enabled: false,
    site: '',
    service: '',
    env: '',
    rum: {
      applicationId: '',
      clientToken: '',
    },
  },

  mapbox: {
    devToken: '',
    prodToken: '',
    streetStyle: '',
    heatmapStyle: '',
  },

  amcharts: {
    license: '',
  },

  analytics: {
    quantum: {
      enabled: false,
      devEndpoint: '',
      prodEndpoint: '',

      config: {
        domain: '',
        appName: '',
        appVersion: '',
        packageVersion: '',
        appEnvName: '',
        startSession: '',
        requirementsVersion: '',
      },
    },

    adobe: {
      enabled: false,
      devEndpoint: '',
      prodEndpoint: '',
    },
  },

  auth: {
    targetBg: PiTargetBg.Default,
    loginUrl: '',
    piClientId: '',
    piEsso: {
      clientId: '',
      redirectUri: '',
    },
    gqlServicesClientId: '',
    autoLogout: {
      idleWarningMins: 5,
      postIdleWarnMins: 5,
    },
  },

  tdcs: {
    enabled: false,
    apiKey: '',
    host: '',
    config: {
      appVersion: '',
      clientType: '',
      deviceId: '',
      osVersion: '',
      deviceType: '',
    },
    localConfig: '', // for local development only
  },

  // Access Google API library
  google: {
    siteKey: '',
  },
};

export default appConfigDefault;
