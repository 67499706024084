/* eslint-disable react/no-array-index-key */
// FIXME: See if we can fix the linting error being disabled above
import React from 'react';
import { Maybe } from '../types/types.generated';

/**
 * Wrap single chars to protect against using regex reserved chars
 * @param search string to sanitize
 */

const sanitize = (search: string) => {
  return search.replace(/[-!$%^&*:;?,.@#()_+|~=`<>{}[\]\\]/g, '\\$&');
};

export const highlight = (
  text: Maybe<string> | undefined,
  query: Maybe<string> | undefined
) => {
  if (!query || (query && !text)) {
    return text;
  }

  let hlText: string[] = [];

  const splitPoint = Date.now().toString();
  const valueStr = `${text}`; // Ensure numeric values are converted to strings
  const guttedText = valueStr.replace(
    new RegExp(sanitize(query), 'gi'),
    (match) => {
      hlText.push(match);
      return `${splitPoint}REPLACEME${splitPoint}`;
    }
  );

  hlText = hlText.reverse();
  return guttedText
    .split(splitPoint)
    .filter((unit) => unit)
    .map((unit, i) =>
      unit === 'REPLACEME' ? (
        <span key={+splitPoint + i} className="highlight">
          {hlText.pop()}
        </span>
      ) : (
        unit
      )
    );
};
