import { QuantumAppEnvNameType } from '../../contexts/types/quantum';
import { getAimHostOverride } from '../../contexts/services/authSessionStorage';
import { IEnvConfig } from '../envConfig/envConfig.types';
import { AppEnv, IAppConfigApiHosts, PiTargetBg } from './appConfig.types';
import { parseAimViewOrgAsClientQuery } from '../../contexts/utils/authUserUtils';

/**
 * Converts app env to its corresponding enum value
 */
export const toAppEnvEnum = (enumValue: string): AppEnv => {
  const keys = Object.keys(AppEnv).filter(
    (x) =>
      AppEnv[x as keyof typeof AppEnv].toLowerCase() === enumValue.toLowerCase()
  );
  const key = keys.length > 0 ? keys[0] : AppEnv.Mock;
  return AppEnv[key as keyof typeof AppEnv];
};

/**
 * Gets the version name (ie: branch name)
 */
export const getAppVersionName = (envConfig: IEnvConfig): string => {
  return envConfig.ci_commit_ref_slug;
};

/**
 * Get the app full version by combining the Gitlab branch (or tag) name and the
 * pipeline ID it was deployed with.
 */
export const getAppPackageVersion = (envConfig: IEnvConfig): string => {
  const name = getAppVersionName(envConfig);
  const buildId = Number(envConfig.ci_pipeline_id);

  return name + (buildId ? `_${buildId}` : '');
};

/**
 * Tell Pi which BE endpoint to route to.
 */
export const getTargetBg = (): PiTargetBg => {
  const hostName = window.location.hostname.toLowerCase();
  if (hostName.startsWith('blue.')) {
    return PiTargetBg.Blue;
  }

  if (hostName.startsWith('green.')) {
    return PiTargetBg.Green;
  }

  return PiTargetBg.Default;
};

/**
 * Get the correct App Url to use for CXDO (AIM) based on whether we are on Blue / Green.
 */
const getCxdoProdHost = (): string => {
  const targetBg = getTargetBg();
  switch (targetBg) {
    case PiTargetBg.Blue:
      return 'https://cxdo-blue.spectrumenterprise.net';
    case PiTargetBg.Green:
      return 'https://cxdo-green.spectrumenterprise.net';
    default:
      return 'https://cxdo.spectrumenterprise.net';
  }
};

/**
 * Get the correct App Url to use for Anonymous Token Federated endpoint based on whether we are on Blue / Green.
 */
const getAnonymousProdHost = (): string => {
  const targetBg = getTargetBg();
  switch (targetBg) {
    case PiTargetBg.Blue:
      return 'https://blue-anonymous-management-p2.apis.spectrumenterprise.net';
    case PiTargetBg.Green:
      return 'https://green-anonymous-management-p2.apis.spectrumenterprise.net';
    default:
      return 'https://anonymous-management-p2.apis.spectrumenterprise.net';
  }
};

/**
 * Get the correct App Url to use for SSO based on whether we are on Blue / Green.
 */
const getSSOProdHost = (): string => {
  const targetBg = getTargetBg();
  switch (targetBg) {
    case PiTargetBg.Blue:
      return 'https://blue-enterprise.apis.spectrumenterprise.net';
    case PiTargetBg.Green:
      return 'https://green-enterprise.apis.spectrumenterprise.net';
    default:
      return 'https://enterprise.apis.spectrumenterprise.net';
  }
};

/**
 * Get the correct Host Url to use for webSocket based on whether we are on Blue / Green.
 */
const getWsVoiceCallGuardUpdatesProdHost = (): string => {
  const targetBg = getTargetBg();
  switch (targetBg) {
    case PiTargetBg.Blue:
      return 'wss://blue-dgs-callguard-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
    case PiTargetBg.Green:
      return 'wss://green-dgs-callguard-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
    default:
      return 'wss://dgs-callguard-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
  }
};

const getWsBusinessInternetRestartUpdatesProdHost = (): string => {
  const targetBg = getTargetBg();
  switch (targetBg) {
    case PiTargetBg.Blue:
      return 'wss://blue-dgs-device-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
    case PiTargetBg.Green:
      return 'wss://green-dgs-device-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
    default:
      return 'wss://dgs-device-subscription-prod-p2.apis.spectrumenterprise.net/subscriptions';
  }
};

/**
 * Be sure we use the correct CXDO host for Lower Environments as
 * there are many ways to approach this to keep things in sync.
 * @param defaultCxdoHost
 */
const getCxdoLowerEnvHost = (defaultCxdoHost: string): string => {
  // Above all, if this is a release, we want SENET and CXDO to be using the same url for redirects.
  const isReleaseBranch = window.location.origin.includes('://release-');
  // Can't use Feature Toggles for appConfig which is a higher context so hard coding toggle here
  const useLegacyAngularCxdo = false;

  if (isReleaseBranch) {
    // senet react url ex: https://release-zookeeper--uat.senet.spectrumtoolbox.com
    // cxdo react url ex: https://release-zookeeper--uat.aim.spectrumtoolbox.com
    // cxdo angular url ex: https://release-zookeeper-uat.cxdo.spectrumtoolbox.com

    if (useLegacyAngularCxdo) {
      return window.location.origin
        .replace('.senet.', '.cxdo.')
        .replace('--', '-');
    }

    return window.location.origin.replace('.senet.', '.aim.');
  }

  // First, check if CXDO Host is in the QueryParams (only happens on redirect from CXDO "View as Client")
  const { aimHostOverride } = parseAimViewOrgAsClientQuery();

  /**
   * If not in the QueryParams, check local storage as the QueryParams from the redirect above
   * might have already been processed.
   */
  const aimHostOverrideUrl = getAimHostOverride();

  let cxdoHost = defaultCxdoHost;
  if (useLegacyAngularCxdo) {
    cxdoHost = defaultCxdoHost.replace('.aim.', '.cxdo.').replace('--', '-');
  }

  return aimHostOverride ?? aimHostOverrideUrl ?? cxdoHost;
};

/**
 * Generate a fake mock url to use for the path handlers that
 * the Mock Service will intercept.
 * Note: These are fake urls.
 * @param appShortName The app short name you want to build for.
 */
export const genMockHost = (appShortName: string): string =>
  `https://mock-${appShortName}.spectrumenterprise.net`;

/**
 * Generate mock endpoints for all API hosts
 * NOTE: Not a real url domain, just a placeholder that will be intercepted
 * by the Mock Service Worker.
 */
export const mockEndpoints = (): IAppConfigApiHosts => ({
  piHost: genMockHost('pinxt'),
  authAnonymousHost: genMockHost('auth-anonymous'),
  directHost: genMockHost('senet'),
  directLegacyHost: genMockHost('senet'),
  cxdoHost: genMockHost('cxdo'),
  directSSOHost: genMockHost('senet'),
  // TODO: Figure out how to mock WebSockets
  wsVoiceCallGuardUpdatesHost: '',
  wsBusinessInternetRestartUpdatesHost: '',
  ravenHost: '',
});

/**
 * Get the API Host name to use for API calls
 * @param appEnv current app env
 */
export const getApiHost = (appEnv: AppEnv): IAppConfigApiHosts => {
  /**
   * NOTE: Due to PiNxt environment limitations, ApiHost needs to have below configs:
   * SENET ENGPROD -> PiNxt QA -> BE ENGPROD
   * SENET UAT -> PiNxt UAT -> BE UAT
   * SENET QA (FKA Engnew) -> PiNxt DEV -> BE QA
   *
   * TODO: These can potentially change at a later date
   */

  switch (appEnv) {
    case AppEnv.Prod:
      return {
        piHost: 'https://apis.spectrum.net',
        authAnonymousHost: getAnonymousProdHost(),
        directHost: '', // TODO: Figure out what this is
        directLegacyHost: 'https://enterprise.prod.spectrumenterprise.net',
        cxdoHost: getCxdoProdHost(),
        directSSOHost: getSSOProdHost(),
        wsVoiceCallGuardUpdatesHost: getWsVoiceCallGuardUpdatesProdHost(),
        wsBusinessInternetRestartUpdatesHost:
          getWsBusinessInternetRestartUpdatesProdHost(),
        ravenHost: 'https://media.raven.news',
      };

    case AppEnv.EngProd:
      return {
        piHost: 'https://apis.shared.qa-spectrum.net',
        authAnonymousHost:
          import.meta.env.VITE_APP_AUTH_ANON_HOST_ENGPROD ||
          'https://dgs-common-anonymous-management-engprod-p2.graphql.enterprise.spectrumtoolbox.com',
        directHost:
          import.meta.env.VITE_APP_DIRECT_HOST_ENGPROD ||
          'https://fed-engprod-p2.graphql.enterprise.spectrumtoolbox.com',
        directLegacyHost:
          import.meta.env.VITE_APP_DIRECT_LEGACY_HOST_ENGPROD ||
          'https://engprod.api.enterprise.spectrumtoolbox.com',
        cxdoHost: getCxdoLowerEnvHost(
          import.meta.env.VITE_APP_CXDO_HOST_ENGPROD ||
            'https://develop-engprod.aim.spectrumtoolbox.com'
        ),
        directSSOHost:
          import.meta.env.VITE_APP_DIRECT_SSO_HOST_ENGPROD ||
          'https://engprod.se-web.spectrumtoolbox.com',
        wsVoiceCallGuardUpdatesHost:
          'wss://dgs-callguard-subscription-engprod-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        wsBusinessInternetRestartUpdatesHost:
          'wss://dgs-device-subscription-engprod-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        ravenHost: 'https://media.stage-raven.news',
      };

    case AppEnv.Uat:
      return {
        piHost: 'https://apis.shared.uat-spectrum.net',
        authAnonymousHost:
          import.meta.env.VITE_APP_AUTH_ANON_HOST_UAT ||
          'https://dgs-common-anonymous-management-uat-p2.graphql.enterprise.spectrumtoolbox.com',
        directHost:
          import.meta.env.VITE_APP_DIRECT_HOST_UAT ||
          'https://fed-uat-p2.graphql.enterprise.spectrumtoolbox.com',
        directLegacyHost:
          import.meta.env.VITE_APP_DIRECT_LEGACY_HOST_UAT ||
          'https://uat.api.enterprise.spectrumtoolbox.com',
        cxdoHost: getCxdoLowerEnvHost(
          import.meta.env.VITE_APP_CXDO_HOST_UAT ||
            'https://develop-uat.aim.spectrumtoolbox.com'
        ),
        directSSOHost:
          import.meta.env.VITE_APP_DIRECT_SSO_HOST_UAT ||
          'https://uat.se-web.spectrumtoolbox.com',
        wsVoiceCallGuardUpdatesHost:
          'wss://dgs-callguard-subscription-uat-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        wsBusinessInternetRestartUpdatesHost:
          'wss://dgs-device-subscription-uat-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        ravenHost: 'https://media.qa.mds.spectrumflow.net',
      };

    case AppEnv.QA:
      return {
        piHost: 'https://apis.shared.dev-spectrum.net',
        authAnonymousHost:
          import.meta.env.VITE_APP_AUTH_ANON_HOST_QA ||
          'https://dgs-common-anonymous-management-qa-p2.graphql.enterprise.spectrumtoolbox.com',
        directHost:
          import.meta.env.VITE_APP_DIRECT_HOST_QA ||
          'https://fed-qa-p2.graphql.enterprise.spectrumtoolbox.com',
        directLegacyHost:
          import.meta.env.VITE_APP_DIRECT_LEGACY_HOST_QA ||
          'https://qa.api.enterprise.spectrumtoolbox.com',
        cxdoHost: getCxdoLowerEnvHost(
          import.meta.env.VITE_APP_CXDO_HOST_QA ||
            'https://develop-qa.aim.spectrumtoolbox.com'
        ),
        directSSOHost:
          import.meta.env.VITE_APP_DIRECT_SSO_HOST_QA ||
          'https://qa.se-web.spectrumtoolbox.com',
        wsVoiceCallGuardUpdatesHost:
          'wss://dgs-callguard-subscription-qa-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        wsBusinessInternetRestartUpdatesHost:
          'wss://dgs-device-subscription-qa-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        ravenHost: 'https://media.qa.mds.spectrumflow.net',
      };

    /** Currently no Dev Env, setup here if needed  */
    case AppEnv.Dev:
      return {
        piHost: '',
        authAnonymousHost: process.env.REACT_APP_AUTH_ANON_HOST_DEV || '',
        directHost: process.env.REACT_APP_DIRECT_HOST_DEV || '',
        directLegacyHost: process.env.REACT_APP_DIRECT_LEGACY_HOST_DEV || '',
        cxdoHost: getCxdoLowerEnvHost(
          import.meta.env.VITE_APP_CXDO_HOST_DEV || ''
        ),
        directSSOHost: process.env.REACT_APP_DIRECT_SSO_HOST_DEV || '',
        wsVoiceCallGuardUpdatesHost:
          'wss://dgs-callguard-subscription-dev-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        wsBusinessInternetRestartUpdatesHost:
          'wss://dgs-device-subscription-dev-p2.graphql.enterprise.spectrumtoolbox.com/subscriptions',
        ravenHost: '',
      };

    default:
      return mockEndpoints();
  }
};

/**
 * Get the full graphQL endpoints
 * @param apiHost the current host
 * @param useDirectHost whether or not to use the direct host
 */
export const getGraphQlEndpoints = (
  apiHost: string,
  useDirectHost = false
): { graphql: string; anonymous: string } => {
  if (useDirectHost) {
    return {
      graphql: `${apiHost}/graphql`,
      anonymous: `${apiHost}/graphql`,
    };
  }

  return {
    graphql: `${apiHost}/entservices-graphql-p2/graphql`,
    anonymous: `${apiHost}/ent-anonymous-p2/graphql`,
  };
};

/**
 * Get the full REST endpoint url
 * @param apiHost the current host
 * @param useDirectHost whether or not to use the direct host
 */
export const getRestEndpoint = (
  apiHost: string,
  useDirectHost = false
): string => {
  if (useDirectHost) {
    return apiHost;
  }
  return `${apiHost}/entservices`;
};

/**
 * Get the correct device type name of the tdcs config to use.
 * @param appName current app name
 * @param appEnv current app env
 */
export const getTdcsDeviceType = (appName: string, appEnv: AppEnv): string => {
  switch (appEnv) {
    case AppEnv.QA:
      return `CLIENT-${appName}-QA`.toUpperCase();
    case AppEnv.Uat:
      return `CLIENT-${appName}-UAT`.toUpperCase();
    case AppEnv.EngProd:
      return `CLIENT-${appName}-ENGPROD`.toUpperCase();
    case AppEnv.Prod:
      return `CLIENT-${appName}-PROD`.toUpperCase();
    default:
      return `CLIENT-${appName}`.toUpperCase();
  }
};

/**
 * Attempts to determine the environment by URL.
 */
export const getAppEnvByUrl = (): AppEnv => {
  const host = window.location.hostname.toLowerCase();

  if (
    host === 'spectrumenterprise.net' ||
    host === 'blue.spectrumenterprise.net' ||
    host === 'green.spectrumenterprise.net'
  ) {
    return AppEnv.Prod;
  }

  if (host.includes('engprod')) {
    return AppEnv.EngProd;
  }

  if (host.includes('uat')) {
    return AppEnv.Uat;
  }

  if (host.includes('qa')) {
    return AppEnv.QA;
  }

  if (host.includes('dev')) {
    return AppEnv.Dev;
  }

  if (host.includes('demo.')) {
    return AppEnv.Demo;
  }

  if (host.includes('demo-stage.')) {
    return AppEnv.DemoStage;
  }

  return AppEnv.Mock;
};

/**
 * Get the current app environment based on the URL
 */
export const getAppEnv = (envConfig?: IEnvConfig): AppEnv => {
  // Used for Local Development
  if (import.meta.env.VITE_APP_APP_ENV) {
    return toAppEnvEnum(import.meta.env.VITE_APP_APP_ENV as string);
  }

  // All Apps being deployed via PDE pipeline will use this var to determine env.
  if (envConfig && envConfig['app-env']) {
    return toAppEnvEnum(envConfig['app-env']);
  }

  // This is only used as a fall-back if for some reason the above logic falls thru.
  return getAppEnvByUrl();
};

/**
 * Whether or not the app environment is a prod based environment.
 * @param appEnv current app environment
 */
export const isProd = (appEnv: AppEnv): boolean => {
  return [AppEnv.Prod, AppEnv.Demo].some((env) => env === appEnv);
};

/**
 * Whether or not the app environment is the true, public facing, production environment.
 */
export const isTrueProd = (): boolean => {
  const hostname = window.location.hostname.toLowerCase();
  return (
    hostname === 'spectrumenterprise.net' ||
    hostname === 'www.spectrumenterprise.net'
  );
};

/**
 * Whether or not the app environment is a mock based environment.
 * @param appEnv current app environment
 */
export const isMock = (appEnv: AppEnv): boolean => {
  return [AppEnv.Demo, AppEnv.DemoStage, AppEnv.Mock].some(
    (env) => env === appEnv
  );
};

/**
 * Whether or not the app environment is a Demo environment.
 * @param appEnv current app environment
 */
export const isDemo = (appEnv: AppEnv): boolean => {
  return [AppEnv.Demo, AppEnv.DemoStage].some((env) => env === appEnv);
};

/**
 * Whether or not the app environment is Prod Inactive environment.
 * @param appEnv current app environment
 */
export const isProdInactive = (appEnv: AppEnv): boolean => {
  return isProd(appEnv) && !isTrueProd() && !isDemo(appEnv);
};

/**
 * Get the proper app env for Quantum Analytics
 * @param appEnv current app environment
 */
export const getQuantumEnvName = (appEnv: AppEnv): QuantumAppEnvNameType => {
  switch (appEnv) {
    case AppEnv.Dev:
      return 'dev';
    case AppEnv.QA:
      return 'qa';
    case AppEnv.Uat:
      return 'uat';
    case AppEnv.EngProd:
      return 'engprod';
    case AppEnv.Prod:
      return 'prod';
    default:
      return 'mock';
  }
};

/**
 * Get the correct Env to use for DataDog RUM.
 */
export const getRumEnv = (appEnv: AppEnv): string => {
  if (isProdInactive(appEnv)) {
    return 'inactive';
  }

  return appEnv.toLowerCase();
};

/**
 * Get the correct Prod vs Lower Env Keys to use for DataDog RUM.
 */
export const getRumKeys = (appEnv: AppEnv) => {
  // TODO: Should we include Demo in Prod DataDog?
  if (isProd(appEnv) && !isDemo(appEnv)) {
    return {
      applicationId: 'c4d8f54b-fcce-4764-ad93-0e78c056c80b',
      clientToken: 'puba67df1f28572dd548cccca3966a3d5f4',
    };
  }

  return {
    applicationId: '6bd1fce7-1e9c-41cd-bb96-63e875444be9',
    clientToken: 'pub7a6ccfd97a53cdced04790b4e1692d44',
  };
};

/**
 * Some parts of the application won't function correctly when ran locally.
 */
export const isLocalhost = (): boolean => {
  return window.location.hostname.toLowerCase().startsWith('localhost');
};

/**
 * Get the Endpoint to use for Authentication
 * @param piHost current PiHost based on current env
 * @param appEnv the current env
 */
export const getAuthEndpoint = (piHost: string, appEnv: AppEnv): string => {
  const piNxtRoute = 'auth/oauth/v2';

  // All Mock-like envs need to authenticate with real ESSO
  const isMockEnv = isDemo(appEnv) || isMock(appEnv);
  if (isMockEnv) {
    const hostName = window.location.hostname.toLowerCase();
    const demoOnProd = hostName.startsWith('demo.');

    if (demoOnProd) {
      // Use Prod PiHostESSO Auth for Demo based Prod envs.
      const { piHost: prodPiHost } = getApiHost(AppEnv.Prod);
      return `${prodPiHost}/${piNxtRoute}`;
    }

    // // For ALL lowers, Demo will use UAT for auth // todo: change back to UAT
    const { piHost: uatPiHost } = getApiHost(AppEnv.Uat);
    return `${uatPiHost}/${piNxtRoute}`;
  }

  // Default for all non-demo envs.
  return `${piHost}/${piNxtRoute}`;
};

/**
 * Get where to redirect the user post-ESSO success
 */
export const getPiEssoInfo = (
  cxdoHost: string,
  appEnv: AppEnv,
  piClientId: string
): { clientId: string; redirectUri: string } => {
  const isDemoEnv = isDemo(appEnv) || isMock(appEnv);

  /**
   * All Demo envs need to authenticate with real ESSO BUT
   * redirect back to the Demo site opposed to OpsCenter.
   */
  if (isDemoEnv) {
    return {
      clientId: piClientId,
      redirectUri: window.location.origin,
    };
  }

  // once ESSO is successful, redirect user to OpsCenter
  return {
    clientId: 'enterprise-cxdo',
    redirectUri: cxdoHost,
  };
};
